import React from 'react';
import ReactCardFlip from 'react-card-flip';
import "../index.css";

//MUI
import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


// Images
import sectiona from '../images/cardImages/sectiona.webp'
import sectionb from '../images/cardImages/sectionb.webp'
import section1 from '../images/cardImages/section1.webp'
import section2 from '../images/cardImages/section2.webp'
import section3 from '../images/cardImages/section3.webp'
import section4 from '../images/cardImages/section4.webp'
import section5 from '../images/cardImages/section5.webp'
import section6 from '../images/cardImages/section6.webp'
import section7 from '../images/cardImages/section7.webp'
import section8 from '../images/cardImages/section8.webp'
import section9 from '../images/cardImages/section9.webp'


const Sections = () => {

    const [flip1, setFlip1] = React.useState(false);
    const [flip2, setFlip2] = React.useState(false);
    const [flip3, setFlip3] = React.useState(false);
    const [flip4, setFlip4] = React.useState(false);
    const [flip5, setFlip5] = React.useState(false);
    const [flip6, setFlip6] = React.useState(false);
    const [flip7, setFlip7] = React.useState(false);
    const [flip8, setFlip8] = React.useState(false);
    const [flip9, setFlip9] = React.useState(false);

    return (
        <Box >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Card raised component="a" href='/sectiona' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                            image={sectiona}
                            alt="card_image"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    Section A
                                </Typography>
                                <Typography mt={1} variant="subtitle1" component="div">
                                    General Disclosures
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Card raised component="a" href='/sectionb' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                            image={sectionb}
                            alt="card_image"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    Section B
                                </Typography>
                                <Typography mt={1} variant="subtitle1" component="div">
                                    Policy and Management Processes
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip1(true) }} onMouseOut={() => { setFlip1(false) }}>
                    <ReactCardFlip isFlipped={flip1} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc1' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section1}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 1
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Integrity and Ethics
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc1' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 1
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should conduct and govern themselves with integrity, and in a manner that is ethical, transparent, and accountable.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip2(true) }} onMouseOut={() => { setFlip2(false) }}>
                    <ReactCardFlip isFlipped={flip2} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc2' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section2}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 2
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Sustainable Goods and Services
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc2' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 2
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should provide goods and services in a manner that is sustainable and safe.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip3(true) }} onMouseOut={() => { setFlip3(false) }}>
                    <ReactCardFlip isFlipped={flip3} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc3' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section3}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 3
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Employees Wellbeing
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc3' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 3
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should respect and promote the well-being of all employees, including those in their value chains.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip4(true) }} onMouseOut={() => { setFlip4(false) }}>
                    <ReactCardFlip isFlipped={flip4} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc4' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section4}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 4
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Stakeholders Interest
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc4' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 4
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should respect the interests of and be responsive to all its stakeholders.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip5(true) }} onMouseOut={() => { setFlip5(false) }}>
                    <ReactCardFlip isFlipped={flip5} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc5' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section5}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 5
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Human Rights
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc5' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 5
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should respect and promote human rights.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip6(true) }} onMouseOut={() => { setFlip6(false) }}>
                    <ReactCardFlip isFlipped={flip6} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc6' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section6}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 6
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Environment
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc6' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 6
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should respect and make efforts to protect and restore the environment.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip7(true) }} onMouseOut={() => { setFlip7(false) }}>
                    <ReactCardFlip isFlipped={flip7} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc7' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section7}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 7
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Regulatory Policy
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc7' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 7
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses, when engaging in influencing public and regulatory policy, should do so in a manner that is responsible and transparent.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip8(true) }} onMouseOut={() => { setFlip8(false) }}>
                    <ReactCardFlip isFlipped={flip8} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc8' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section8}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 8
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Inclusive Growth
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc8' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 8
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should promote inclusive growth and equitable development.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} onMouseOver={() => { setFlip9(true) }} onMouseOut={() => { setFlip9(false) }}>
                    <ReactCardFlip isFlipped={flip9} flipDirection="vertical">
                        <Card raised component="a" href='/sectionc9' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 180, backgroundColor: "#f7f5f5", padding: '20px', objectFit: 'contain' }}
                                image={section9}
                                alt="card_image"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 9
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1" component="div">
                                        Consumer and IT
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        <Card raised component="a" href='/sectionc9' sx={{ display: 'flex', width: '100%', height: 180, backgroundColor: "#5A6054", color: "#ffffff", textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Principle 9
                                    </Typography>
                                    <Typography mt={1} variant="subtitle2" align="justify" component="div">
                                        Businesses should engage with and provide value to their consumers in a responsible manner.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </ReactCardFlip>
                </Grid>
            </Grid>
        </Box>
    )
}


export default Sections;