import React from 'react';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


//Images
import im1 from '../../images/SDG/1.jpg';
import im5 from '../../images/SDG/5.jpg';
import im11 from '../../images/SDG/11.jpg';
import im16 from '../../images/SDG/16.jpg';
import section4 from '../../images/Banners/section4.webp'
import pyramid from '../../images/pyramid.webp'


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(2);
  }
  `}
`;


const Sectionc4 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true);
        setExpanded1(true);
        setExpanded2(true);
        setExpanded3(true);
        setExpanded4(true);
        setExpanded5(true);
    };
    const closeAll = () => {
        setExpand(false);
        setExpanded1(false);
        setExpanded2(false);
        setExpanded3(false);
        setExpanded4(false);
        setExpanded5(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 4
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Stakeholders Interest
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should respect the interests of and be responsive to all its stakeholders
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im1} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im5} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im11} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im16} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section4})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box px={4} py={6} mt={8} sx={{ backgroundColor: '#f7f7ef', borderRadius: '8px' }}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box ml={{ lg: 3 }}>
                            <img src={pyramid} height='300' style={{ border: "14px solid #fff" }} alt="stakeholder" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8}>
                        <Box mb={2} sx={{ maxHeight: 310, overflowY: "scroll" }} className="scroll">
                            <Typography variant='h6' sx={{ textAlign: "justify", marginRight: { xs: '20px', md: '30px' }, color: "#67725d", fontWeight: "900" }}>
                                <span style={{ fontSize: '34px', fontWeight: 'bolder' }}>W</span>e strive to create an inclusive society, that empower our stakeholder's positive growth potential, and unleash the potential of sustainable energy to replenish the planet in a safe, sustainable, and responsible way. We strive to create an inclusive society that empowers our stakeholders' positive growth potential and unleash the capability of sustainable.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Describe the processes for identifying key stakeholder groups of the entity.
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Stakeholder Mapping and Engagement Plan SOP is in place, which defines the stakeholder and their mapping. Our unitholders and bond holders, Discoms, local
                            area/community in which we operate, contractors, suppliers, employees - Direct and indirect are all identified stakeholders.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. List stakeholder groups identified as key for your entity and the frequency of engagement with each stakeholder group.
                            <ExpandMore
                                expand={expanded2}
                                onClick={handleExpandClick2}
                                aria-expanded={expanded2}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>S.
                                            No.</strong></TableCell>
                                        <TableCell align="center" ><strong>Stakeholder
                                            Group</strong></TableCell>
                                        <TableCell align="center" ><strong>Whether identified as
                                            Vulnerable &
                                            Marginalized Group
                                            (Yes/No)
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Channels of communication (Email, SMS,
                                            Newspaper, Pamphlets, Advertisement,
                                            Community Meetings, Notice Board, Website),
                                            Other
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Frequency of engagement
                                            (Annually/ Half yearly/
                                            Quarterly / others – please
                                            specify)
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Purpose and scope of engagement including key topics and concerns raised during
                                            such engagement
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            1
                                        </TableCell>
                                        <TableCell align='center'>Unitholder and Bond
                                            Holders -
                                        </TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell >Website, Email
                                        </TableCell>
                                        <TableCell >Annually</TableCell>
                                        <TableCell ></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            2
                                        </TableCell>
                                        <TableCell align='center'>DISCOMS</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell >
                                            Meetings
                                        </TableCell>
                                        <TableCell >Monthly</TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            3
                                        </TableCell>
                                        <TableCell align='center'>General public &
                                            local area operations</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell >In person interactions</TableCell>
                                        <TableCell >Need basis and annually</TableCell>
                                        <TableCell ></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            4
                                        </TableCell>
                                        <TableCell align='center'>Suppliers</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell >
                                            Meetings
                                        </TableCell>
                                        <TableCell >Monthly</TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            5
                                        </TableCell>
                                        <TableCell align='center'>Employees
                                        </TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell >Teams Meeting, monthly Newsletter</TableCell>
                                        <TableCell >Senior /Management Level
                                            Employees - Weekly
                                            Other Employees -Monthly
                                        </TableCell>
                                        <TableCell >Weekly business review.
                                            Monthly Business updates</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            6
                                        </TableCell>
                                        <TableCell align='center'>Other regulatory
                                            authorities </TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell >Website, Email
                                        </TableCell>
                                        <TableCell >As and when required as per
                                            applicable laws</TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Provide the processes for consultation between stakeholders and the Board on economic, environmental, and social topics or if consultation is delegated, how is feedback from such consultations
                            provided to the Board.
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Environmental and social topics are being discussed at Board Meetings. The CEO and COO regularly updates the Board.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Whether stakeholder consultation is used to support the identification and management of environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs received
                            from stakeholders on these topics were incorporated into policies and activities of the entity.
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Yes. We have regular interaction with regulatory bodies, local public administration, DISCOMs, value chain partners, special interest group as NGOs, and community on
                            business activities and overall development of the project area.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/ marginalized stakeholder groups.
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            We have stakeholder mapping and engagement procedure in place however, vulnerable/marginalized stakeholder are considered as community members.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc4;