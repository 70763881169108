import React from 'react';
import Counter from '../ReusableComponents/counter';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Button from '@mui/material/Button';

//images
import im2 from '../../images/SDG/2.jpg';
import im3 from '../../images/SDG/3.jpg';
import im6 from '../../images/SDG/6.jpg';
import im7 from '../../images/SDG/7.jpg';
import im8 from '../../images/SDG/8.jpg';
import im9 from '../../images/SDG/9.jpg';
import im10 from '../../images/SDG/10.jpg';
import im11 from '../../images/SDG/11.jpg';
import im12 from '../../images/SDG/12.jpg';
import im13 from '../../images/SDG/13.jpg';
import im14 from '../../images/SDG/14.jpg';
import im15 from '../../images/SDG/15.jpg';
import section6 from '../../images/Banners/section6.webp'

import WaterDropIcon from '@mui/icons-material/WaterDrop';
import Co2Icon from '@mui/icons-material/Co2';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import DeleteIcon from '@mui/icons-material/Delete';

//chart js
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';



const counterData = [
    {
        "num": 22458,
        "label": "Total energy consumption",
        "icon": ElectricBoltIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": " GJ"
    },
    {
        "num": 398419,
        "label": "Water Withdrawal",
        "icon": WaterDropIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "kilolitres"
    },
    {
        "num": 0,
        "label": "Total Scope 1 Emissions",
        "icon": Co2Icon,
        "prefix": "0",
        "isdecimal": false,
        "suffix": "MTCO₂e"
    },
    {
        "num": 6307,
        "label": "Total Scope 2 Emissions",
        "icon": Co2Icon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "MTCO₂e"
    },
    {
        "num": 225,
        "label": "Total Scope 3 Emissions",
        "icon": Co2Icon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "MTCO₂e"
    },
    {
        "num": 28.92,
        "label": "Total Waste generated",
        "icon": DeleteIcon,
        "prefix": "",
        "isdecimal": true,
        "suffix": "MT"
    },
]

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);

export const options5 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Water withdrawal (in kilolitres) for FY 2022-23',
        },
    },
};

export const data5 = {
    labels: ['Surface water', 'Groundwater', 'Third party water', 'Seawater / desalinated water', 'Others'],
    datasets: [
        {
            label: 'Withdrawal in kilolitres',
            data: [331880, 33349, 25995, 0, 7195],
            backgroundColor: [
                'rgba(103,114,93, 0.8)',
                'rgba(0,66,130,0.7)',
                'rgba(193,74,28, 0.7)',
                'rgba(153, 102, 255, 0.7)',
                'rgba(75, 192, 192, 0.7)',
            ],
            borderColor: [
                'rgba(103,114,93, 1)',
                'rgba(0,66,130,1)',
                'rgba(193,74,28,1)',
                'rgba(153, 102, 255, 1)',
                'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
        },
    ],
};



const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(1.8);
  }
  `}
`;



const Sectionc6 = () => {

    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded3g, setExpanded3g] = React.useState(false);
    const handleExpandClick3g = () => {
        setExpanded3g(!expanded3g);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expanded10, setExpanded10] = React.useState(false);
    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    };

    const [expanded11, setExpanded11] = React.useState(false);
    const handleExpandClick11 = () => {
        setExpanded11(!expanded11);
    };

    const [expanded12, setExpanded12] = React.useState(false);
    const handleExpandClick12 = () => {
        setExpanded12(!expanded12);
    };

    const [expanded13, setExpanded13] = React.useState(false);
    const handleExpandClick13 = () => {
        setExpanded13(!expanded13);
    };


    const [expanded14, setExpanded14] = React.useState(false);
    const handleExpandClick14 = () => {
        setExpanded14(!expanded14);
    };

    const [expanded15, setExpanded15] = React.useState(false);
    const handleExpandClick15 = () => {
        setExpanded15(!expanded15);
    };

    const [expanded16, setExpanded16] = React.useState(false);
    const handleExpandClick16 = () => {
        setExpanded16(!expanded16);
    };

    const [expanded17, setExpanded17] = React.useState(false);
    const handleExpandClick17 = () => {
        setExpanded17(!expanded17);
    };

    const [expanded18, setExpanded18] = React.useState(false);
    const handleExpandClick18 = () => {
        setExpanded18(!expanded18);
    };

    const [expanded19, setExpanded19] = React.useState(false);
    const handleExpandClick19 = () => {
        setExpanded19(!expanded19);
    };

    const [expanded20, setExpanded20] = React.useState(false);
    const handleExpandClick20 = () => {
        setExpanded20(!expanded20);
    };

    const [expanded21, setExpanded21] = React.useState(false);
    const handleExpandClick21 = () => {
        setExpanded21(!expanded21);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true); setExpanded1(true); setExpanded2(true); setExpanded3(true); setExpanded3g(true); setExpanded4(true); setExpanded5(true); setExpanded6(true); setExpanded7(true); setExpanded8(true); setExpanded9(true); setExpanded10(true); setExpanded11(true); setExpanded12(true); setExpanded13(true); setExpanded14(true); setExpanded15(true); setExpanded16(true); setExpanded17(true); setExpanded18(true); setExpanded19(true); setExpanded20(true); setExpanded21(true);
    };
    const closeAll = () => {
        setExpand(false); setExpanded1(false); setExpanded2(false); setExpanded3(false); setExpanded3g(false); setExpanded4(false); setExpanded5(false); setExpanded6(false); setExpanded7(false); setExpanded8(false); setExpanded9(false); setExpanded10(false); setExpanded11(false); setExpanded12(false); setExpanded13(false); setExpanded14(false); setExpanded15(false); setExpanded16(false); setExpanded17(false); setExpanded18(false); setExpanded19(false); setExpanded20(false); setExpanded21(false);
    };



    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 6
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Environment
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should respect and make efforts to protect and restore the environment
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im2} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im3} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im6} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im7} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im8} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im9} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im10} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im11} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im12} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im13} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im14} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im15} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section6})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box px={4} py={6} mt={8} sx={{ backgroundColor: '#f7f7ef', borderRadius: '8px' }}>
                <Box mb={6} display="flex" justifyContent="center">
                    <Typography variant='h4' sx={{ color: "#67725d", fontSize: "34px", fontWeight: "900" }}>
                        Highlights
                    </Typography>
                </Box>
                <Box>
                    <Counter data={counterData} />
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Details of total energy consumption (in Joules or multiples) and energy intensity
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550 }}>
                                            Total electricity consumption (A)(GJ)
                                        </TableCell>
                                        <TableCell align="center">22,458.30</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total fuel consumption (B)(GJ)
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Energy consumption through other sources (C)(GJ)
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total energy consumption (A+B+C)(GJ)</strong>
                                        </TableCell>
                                        <TableCell align="center">22,458.30</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Energy intensity per rupee of turnover (Total energy
                                            consumption/ turnover in rupees)
                                        </TableCell>
                                        <TableCell align="center">4.26 </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Energy intensity (optional) the relevant metric may be selected by
                                            the entity
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Indicate if any independent assessment/ evaluation/assurance has
                                            been carried out by an external agency? (Y/N) If yes, name of the
                                            external agency
                                        </TableCell>
                                        <TableCell align="center">No</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        2. Does the entity have any sites / facilities identified as designated consumers (DCs) under the Performance, Achieve and Trade
                        (PAT) Scheme of the Government of India? (Yes/No) If yes, disclose whether targets set under the PAT scheme have been
                        achieved. In case targets have not been achieved, provide the remedial action taken, if any
                        <ExpandMore
                            expand={expanded2}
                            onClick={handleExpandClick2}
                            aria-expanded={expanded2}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not Applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        3. Provide details of the following disclosures related to water, in the following format
                        <ExpandMore
                            expand={expanded3}
                            onClick={handleExpandClick3}
                            aria-expanded={expanded3}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                        <ExpandMore
                            expand={expanded3g}
                            onClick={handleExpandClick3g}
                            aria-expanded={expanded3g}
                            aria-label="show more"
                        >
                            <AssessmentOutlinedIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550 }} colSpan={3}>
                                            <strong>Water withdrawal by source (in kilolitres)</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (i) Surface water
                                        </TableCell>
                                        <TableCell align="center">3,31,880 </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (ii) Groundwater
                                        </TableCell>
                                        <TableCell align="center">33,349</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (iii) Third party water
                                        </TableCell>
                                        <TableCell align="center">25,995</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (iv) Seawater / desalinated water
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (v) Others
                                        </TableCell>
                                        <TableCell align="center">7,195</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total volume of water withdrawal (in kilolitres)(i + ii + iii + iv + v)</strong>
                                        </TableCell>
                                        <TableCell align="center">3,98,419</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total volume of water consumption (in kilolitre)</strong>
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Water intensity per rupee of turnover (Water consumed / turnover)</strong>
                                        </TableCell>
                                        <TableCell align="center">75</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Water intensity</strong> (optional) the relevant metric may be selected by the entity
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Indicate if any independent assessment/ evaluation/assurance has been
                                            carried out by an external agency? (Y/N) If yes, name of the external
                                            agency.
                                        </TableCell>
                                        <TableCell align="center">
                                            No
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
                <Collapse in={expanded3g} timeout="auto" unmountOnExit>
                    <Box mb={5} sx={{ mx: "auto", width: { xs: '80%', md: '65%' }, minHeight: { xs: '250px', md: '330px' } }}>
                        <Doughnut options={options5} data={data5} />
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        4. Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its coverage and implementation
                        <ExpandMore
                            expand={expanded4}
                            onClick={handleExpandClick4}
                            aria-expanded={expanded4}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not Applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        5. Please provide details of air emissions (other than GHG emissions) by the entity, in the following format
                        <ExpandMore
                            expand={expanded5}
                            onClick={handleExpandClick5}
                            aria-expanded={expanded5}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>Unit</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            NOx
                                        </TableCell>
                                        <TableCell align="center" colSpan={2} rowSpan={7}>No direct emissions are generated due to operation of renewable energy plants.
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            SOx
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Particulate matter (PM)
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Persistent organic pollutants (POP)
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Volatile organic compounds (VOC)
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Hazardous air pollutants (HAP)
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Others – please specify
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box mb={4} mt={4}>
                        <Typography variant='body1'>
                            Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        6. Provide details of Greenhouse Gas emissions (Scope 1 and Scope 2 emissions) & its intensity, in the following format
                        <ExpandMore
                            expand={expanded6}
                            onClick={handleExpandClick6}
                            aria-expanded={expanded6}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>Unit</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)
                                        </TableCell>
                                        <TableCell align="center">Metric tonnes of CO2
                                            equivalent
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)

                                        </TableCell>
                                        <TableCell align="center">Metric tonnes of CO2 equivalent</TableCell>
                                        <TableCell align="center">
                                            6,307
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total Scope 1 and Scope 2 emissions
                                            </strong>
                                        </TableCell>
                                        <TableCell align="center">Metric tonnes of CO2 equivalent
                                        </TableCell>
                                        <TableCell align="center">6307</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total Scope 1 and Scope 2 emissions per rupee of turnover
                                            </strong>
                                        </TableCell>
                                        <TableCell align="center">Metric tonnes of CO2
                                            equivalent/ million rupee
                                            of turnover
                                        </TableCell>
                                        <TableCell align="center">1.19</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total Scope 1 and Scope 2 emission intensity (optional)   the relevant metric may be selected by the entity
                                            </strong>
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Indicate if any independent assessment/ evaluation/
                                            assurance has been carried out by an external agency?
                                            (Y/N) If yes, name of the external agency
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}>No
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        7. Does the entity have any project related to reducing Greenhouse Gas emission? If Yes, then provide details.
                        <ExpandMore
                            expand={expanded7}
                            onClick={handleExpandClick7}
                            aria-expanded={expanded7}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            Being renewable energy generator, no separate projects are undertaken.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        8. Provide details related to waste management by the entity, in the following format
                        <ExpandMore
                            expand={expanded8}
                            onClick={handleExpandClick8}
                            aria-expanded={expanded8}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2021-22<br></br>(Previous Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550 }} colSpan={3}>
                                            <strong>Total Waste generated (in metric tonnes)</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Plastic waste (A)
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            E-waste (B)
                                        </TableCell>
                                        <TableCell align="center">28.73*</TableCell>
                                        <TableCell align="center">25</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Bio-medical waste (C)
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Construction and demolition waste (D)
                                        </TableCell>
                                        <TableCell align="center" >-</TableCell>
                                        <TableCell align="center" >-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Battery waste (E)
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Radioactive waste (F)
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Other Hazardous waste. Please specify, if any (G)
                                        </TableCell>
                                        <TableCell align="center">0.022 </TableCell>
                                        <TableCell align="center">0.022 </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Other Non-hazardous waste generated. Please specify, if any (H)
                                            (Break-up by composition i.e. by materials relevant to the sector)
                                        </TableCell>
                                        <TableCell align="center">0.177</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total (A + B + C + D + E + F + G + H)</strong>
                                        </TableCell>
                                        <TableCell align="center"><strong>28.92</strong></TableCell>
                                        <TableCell align="center"><strong>25.22</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center" colSpan={3}>
                                            <strong>
                                                For each category of waste generated, total waste recovered through recycling, re-using or other recovery operations
                                                (in metric tonnes)
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550 }} colSpan={3}>
                                            <strong>Category of waste</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (i) Recycled
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (ii) Re-used
                                        </TableCell>
                                        <TableCell align="center">0.144</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (iii) Other recovery operations
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total</strong>
                                        </TableCell>
                                        <TableCell align="center"><strong>0.144</strong></TableCell>
                                        <TableCell align="center"><strong></strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center" colSpan={3}>
                                            <strong>
                                                For each category of waste generated, total waste disposed by nature of disposal method (in metric tonnes)
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550 }} colSpan={3}>
                                            <strong>Category of waste</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (i) Incineration
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (ii) Landfilling
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            (iii) Other disposal operations
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total</strong>
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={4} mb={4}>
                            <Typography variant='body1'>
                                * 28.73 MT of E-waste is from Inverter replacement and recycling is planned in FY 24 through an authorized e-waste recycler.
                            </Typography>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        9. Briefly describe the waste management practices adopted in your establishments. Describe the strategy adopted by your company to reduce usage of hazardous and toxic chemicals in your products
                        and processes and the practices adopted to manage such wastes.
                        <ExpandMore
                            expand={expanded9}
                            onClick={handleExpandClick9}
                            aria-expanded={expanded9}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            Our sites generate e-waste, which includes replacement of old inverters, panels and wires, as a result of the operation and maintenance of solar power infrastructure.
                            The waste generated is resource rich and includes valuable materials such as steel, and glass. In line with the circular economy approach we hand over this waste to
                            certified recyclers to divert the waste away from landfill and facilitate material-specific recycling.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        10. If the entity has operations/offices in/around ecologically sensitive areas (such as national parks, wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation
                        zones etc.) where environmental approvals / clearances are required, please specify details in the following format:

                        <ExpandMore
                            expand={expanded10}
                            onClick={handleExpandClick10}
                            aria-expanded={expanded10}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded10} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            There are no such plants/offices in the ecologically sensitive areas.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        11. Details of environmental impact assessments of projects undertaken by the entity based on applicable laws, in the current financial year:
                        <ExpandMore
                            expand={expanded11}
                            onClick={handleExpandClick11}
                            aria-expanded={expanded11}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded11} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Box mb={2}>
                            <Typography variant='body1' sx={{ textAlign: "justify" }}>
                                Not applicable, as solar plants are categorized in White category and EIA is exempted.
                            </Typography>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        12. Is the entity compliant with the applicable environmental law/ regulations/ guidelines in India; such as the Water (Prevention and Control of Pollution) Act, Air (Prevention and Control of Pollution)
                        Act, Environment protection act and rules thereunder (Y/N). If not, provide details of all such non-compliances, in the following format:
                        <ExpandMore
                            expand={expanded12}
                            onClick={handleExpandClick12}
                            aria-expanded={expanded12}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded12} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            We are compliant with the applicable environmental law/ regulations/ guidelines in India. Ground water abstraction is done only post obtaining NOC.
                            Due to our operations, no discharge of water / effluent, no air emission, nor noise pollution occurs.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        1. Provide break-up of the total energy consumed (in Joules or multiples) from renewable and non-renewable sources, sources, in the following format:
                        <ExpandMore
                            expand={expanded13}
                            onClick={handleExpandClick13}
                            aria-expanded={expanded13}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded13} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550, backgroundColor: "#e0e0e0" }} colSpan={3} align="center">
                                            <strong>From renewable sources</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total electricity consumption (A) - GJ
                                        </TableCell>
                                        <TableCell align="center">22,458.3</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total fuel consumption (B) - GJ
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Energy consumption through other sources (C) - GJ
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total energy consumed from renewable sources (A+B+C) -GJ</strong>
                                        </TableCell>
                                        <TableCell align="center"><strong>22,458.30</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center" colSpan={3} sx={{ backgroundColor: "#e0e0e0" }}>
                                            <strong>From non-renewable sources</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total electricity consumption (D) - GJ
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total fuel consumption (E) - GJ
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Energy consumption through other sources (F) - GJ
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total energy consumed from non-Renewable sources (D+E+F) - GJ</strong>
                                        </TableCell>
                                        <TableCell align="center"><strong>-</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        2. Provide the following details related to water discharged: Water discharge by destination and level of treatment (in kilolitres)
                        <ExpandMore
                            expand={expanded14}
                            onClick={handleExpandClick14}
                            aria-expanded={expanded14}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded14} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            Not Applicable
                            Refer <a href='https://virescent.co.in/wp-content/uploads/2022/10/2022-GRESB-Public-Disclosure-Report-VRET.pdf' target="_blank" rel="noopener noreferrer">2022-GRESB-Public-Disclosure-Report-VRET.pdf (virescent.co.in)</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        3. Water withdrawal, consumption and discharge in areas of water stress (in kilolitres): Water withdrawal, and consumption in the following format:
                        <ExpandMore
                            expand={expanded15}
                            onClick={handleExpandClick15}
                            aria-expanded={expanded15}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded15} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            Water is used limited quantity for cleaning of solar panels.<br></br>
                            7 Plants are located in water stress areas as per CGWA categorization.<br /><br />
                            <strong>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an externalagency? (Y/N) If yes, name of the external agency</strong>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        4. Please provide details of total Scope 3 emissions & its intensity, in the following format:
                        <ExpandMore
                            expand={expanded16}
                            onClick={handleExpandClick16}
                            aria-expanded={expanded16}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded16} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell><strong>Parameter</strong></TableCell>
                                        <TableCell align="center"><strong>Unit</strong></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total Scope 3 emissions (Break-up of the GHG into CO2, CH4, N2O,
                                                HFCs, PFCs, SF6, NF3, if available)</strong>
                                        </TableCell>
                                        <TableCell align="center">Metric tonnes of CO2
                                            equivalent
                                        </TableCell>
                                        <TableCell align="center">225</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total Scope 3 emissions per million rupee of turnover</strong>
                                        </TableCell>
                                        <TableCell align="center">Metric tonnes of CO2 equivalent/
                                            million rupee of turnover
                                        </TableCell>
                                        <TableCell align="center">
                                            0.04
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            <strong>Total Scope 3 emissionintensity (optional) - the relevant metric
                                                may be selected by the entity
                                            </strong>
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box mb={4} mt={4}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            <strong>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency.</strong><br></br>
                            No
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        5. With respect to the ecologically sensitive areas reported at Question 10 of Essential Indicators above, provide details of significant direct & indirect impact of the entity on biodiversity in such areas
                        along-with prevention and remediation activities.
                        <ExpandMore
                            expand={expanded17}
                            onClick={handleExpandClick17}
                            aria-expanded={expanded17}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded17} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            Not Applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        6. If the entity has undertaken any specific initiatives or used innovative technology or solutions to improve resource efficiency, or reduce impact due to emissions / effluent discharge / waste generated,
                        please provide details of the same as well as outcome of such initiatives, as per the following format:
                        <ExpandMore
                            expand={expanded18}
                            onClick={handleExpandClick18}
                            aria-expanded={expanded18}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded18} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Sr.No</strong></TableCell>
                                        <TableCell align="center"><strong>Initiative
                                            undertaken</strong></TableCell>
                                        <TableCell align="center"><strong>Details of the initiative (Web-link, if any, may be provided along-with summary)</strong></TableCell>
                                        <TableCell align="center"><strong>Outcome of the initiative</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            1
                                        </TableCell>
                                        <TableCell colSpan={3}> Rainwater harvesting installation is completed at all sites .</TableCell>


                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        7. Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.
                        <ExpandMore
                            expand={expanded19}
                            onClick={handleExpandClick19}
                            aria-expanded={expanded19}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded19} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            Yes. Refer <a href='https://virescent.co.in/wp-content/uploads/2022/08/V2_ESG-Report_09_08_22-hi-res.pdf' target="_blank" rel="noopener noreferrer">V2_ESG Report_09_08_22 (virescent.co.in)</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        8. Disclose any significant adverse impact to the environment, arising from the value chain of the entity. What mitigation or adaptation measures have been taken by the entity in this regard.
                        <ExpandMore
                            expand={expanded20}
                            onClick={handleExpandClick20}
                            aria-expanded={expanded20}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded20} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            -
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={4} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        9. Percentage of value chain partners (by value of business done with such partners) that were assessed for environmental impacts.
                        <ExpandMore
                            expand={expanded21}
                            onClick={handleExpandClick21}
                            aria-expanded={expanded21}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded21} timeout="auto" unmountOnExit>
                    <Box mb={2}>
                        <Typography variant='body1' sx={{ textAlign: "justify" }}>
                            No separate assessment is conducted.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc6;