import React from 'react';
import sectionb from '../../images/Banners/sectionb.webp'

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBox1 = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: "1s",
})};
  &:hover {
    transform: scale(1.03);
  }
  `}
`;


const Sectionb = () => {

    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true);
        setExpanded1(true);
        setExpanded3(true);
        setExpanded4(true);
        setExpanded5(true);
    };
    const closeAll = () => {
        setExpand(false);
        setExpanded1(false);
        setExpanded3(false);
        setExpanded4(false);
        setExpanded5(false);
    };


    return (
        <Container>
            <StyledBox1 mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '250px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box p={5} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Management and Process Disclosures
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Policy and Management Processes
                    </Typography>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${sectionb})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '250px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </StyledBox1>
            <Box mt={4} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            Policy and management processes
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"></TableCell>
                                        <TableCell sx={{ width: '240px' }} align="center"><strong>Disclosure Questions</strong></TableCell>
                                        <TableCell align="center"><strong>P1</strong></TableCell>
                                        <TableCell align="center"><strong>P2</strong></TableCell>
                                        <TableCell align="center"><strong>P3</strong></TableCell>
                                        <TableCell align="center"><strong>P4</strong></TableCell>
                                        <TableCell align="center"><strong>P5</strong></TableCell>
                                        <TableCell align="center"><strong>P6</strong></TableCell>
                                        <TableCell align="center"><strong>P7</strong></TableCell>
                                        <TableCell align="center"><strong>P8</strong></TableCell>
                                        <TableCell align="center"><strong>P9</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ backgroundColor: "#e0e0e0" }} component="th" scope="row" align='left' colSpan={11}><strong>Policy and Management Processes</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell rowSpan={3} align='center'>1</TableCell>
                                        <TableCell align='justify'>a. Whether your entity's policy/policies cover each principle and its core elements of the NGRBCs. (Yes/No)</TableCell>
                                        <TableCell align='center'>Partially available</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>No separate policy but covered under QHSE policy</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>No separate policy but covered under HR policy</TableCell>
                                        <TableCell align='center'>No separate policy but covered under QHSE policy</TableCell>
                                        <TableCell align='center'>Yes (Statutory Policies )</TableCell>
                                        <TableCell align='center'>No separate policy but covered under HR policy</TableCell>
                                        <TableCell align='center'>Yes (IT Policy)</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>b. Has the policy been approved by the Board? (Yes/No)</TableCell>
                                        <TableCell align='center'>Existing available policies approved by the Board</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>No separate policy but covered under QHSE policy</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='justify'>c. Web Link of the Policies, if available</TableCell>
                                        <TableCell colSpan={9} align='center'><a href="https://virescent.co.in/corporate-governance/#Keypolices" target="_blank" rel="noopener noreferrer">https://virescent.co.in/corporate-governance/#Keypolices</a></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>2</TableCell>
                                        <TableCell align='justify'>Whether the entity has translated the policy into procedures. (Yes / No)</TableCell>
                                        <TableCell colSpan={9} >Yes. VRET has procedures to the extent covering 9 principles.</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>3</TableCell>
                                        <TableCell align='justify'>Do the enlisted policies extend to your value chain partners? (Yes/No)</TableCell>
                                        <TableCell colSpan={9}>Relevant policies are extended to O&M Contractors. </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>4</TableCell>
                                        <TableCell align='justify'>Name of the national and international codes/ certifications/ labels/ standards (e.g., Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustee) standards (e.g., SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.</TableCell>
                                        <TableCell colSpan={9}>Virescent Renewable Energy Trust policies are based on the NVG principles and conform to the International standards like ISO 9001, 14001, and 45001, 31001, SA8000, ILO principles and United Nations SDGs.  VRET follows GRI, SASB, GRESB standard framework for reporting its  sustainability performance.</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>5</TableCell>
                                        <TableCell align='justify'>Specific commitments, goals and targets set by the entity with defined timelines, if any.</TableCell>
                                        <TableCell colSpan={9}>ESG strategic document is available on <a href="https://virescent.co.in/empowering-sustainable-future/#esg-strategy" target="_blank" rel="noopener noreferrer">https://virescent.co.in/empowering-sustainable-future/#esg-strategy</a> </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>6</TableCell>
                                        <TableCell align='justify'>Performance of the entity against the specific commitments, goals and targets along-with reasons in case the same are not met.</TableCell>
                                        <TableCell colSpan={9}>Goals and Targets are defined for FY 2022 to FY 2030  based on FY21-22 baseline data. </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ backgroundColor: "#e0e0e0" }} component="th" scope="row" align='left' colSpan={11}><strong>Governance, Leadership and Oversight</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>7</TableCell>
                                        <TableCell align='justify'>Statement by director responsible for the business responsibility report, highlighting ESG related challenges, targets and achievements</TableCell>
                                        <TableCell colSpan={9}>Refer <a href="https://virescent.co.in/empowering-sustainable-future/#fostering" target="_blank" rel="noopener noreferrer">https://virescent.co.in/empowering-sustainable-future/#fostering</a> </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>8</TableCell>
                                        <TableCell align='justify'>Details of the highest authority responsible for implementation and oversight of the Business Responsibility policy (ies).</TableCell>
                                        <TableCell colSpan={9}>The ESG Committee of the Board highest authority responsible for implementation and oversight of the Business Responsibility policy (ies).</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>9</TableCell>
                                        <TableCell align='justify'>Does the entity have a specified Committee of the Board/ Director responsible for decision making on sustainability related issues? (Yes / No). If yes, provide details.</TableCell>
                                        <TableCell colSpan={9}>Yes. The ESG Committee of the Board is responsible decision making on sustainability related issues.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            10. Details of Review of NGRBCs by the Company: Indicate whether review was undertaken by Director / Committee of the Board/ Any other Committee
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Subject for review</strong></TableCell>
                                        <TableCell align="center" colSpan={9}><strong>Indicate whether the review was undertaken by the Director/committee of the Board/any other committee</strong></TableCell>
                                        <TableCell align="center" colSpan={9}><strong>Frequency (annually/half yearly/quarterly/other - please specify)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>P1</strong></TableCell>
                                        <TableCell align="center"><strong>P2</strong></TableCell>
                                        <TableCell align="center"><strong>P3</strong></TableCell>
                                        <TableCell align="center"><strong>P4</strong></TableCell>
                                        <TableCell align="center"><strong>P5</strong></TableCell>
                                        <TableCell align="center"><strong>P6</strong></TableCell>
                                        <TableCell align="center"><strong>P7</strong></TableCell>
                                        <TableCell align="center"><strong>P8</strong></TableCell>
                                        <TableCell align="center"><strong>P9</strong></TableCell>
                                        <TableCell align="center"><strong>P1</strong></TableCell>
                                        <TableCell align="center"><strong>P2</strong></TableCell>
                                        <TableCell align="center"><strong>P3</strong></TableCell>
                                        <TableCell align="center"><strong>P4</strong></TableCell>
                                        <TableCell align="center"><strong>P5</strong></TableCell>
                                        <TableCell align="center"><strong>P6</strong></TableCell>
                                        <TableCell align="center"><strong>P7</strong></TableCell>
                                        <TableCell align="center"><strong>P8</strong></TableCell>
                                        <TableCell align="center"><strong>P9</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Performance against above policies and follow up action</TableCell>
                                        <TableCell align='center' rowSpan={2} colSpan={9}>Yes</TableCell>
                                        <TableCell colSpan={9} rowSpan={2}>As and when required or due to change in regulations or law- Half yearly</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Compliance with statutory requirements of relevance to the principles, and rectification of any non - compliances</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            11. Has the entity carried out independent assessment/ evaluation of the working of its policies by an external agency? (Yes/No). If yes, provide name of the agency.
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={4}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>P1</strong></TableCell>
                                        <TableCell align="center"><strong>P2</strong></TableCell>
                                        <TableCell align="center"><strong>P3</strong></TableCell>
                                        <TableCell align="center"><strong>P4</strong></TableCell>
                                        <TableCell align="center"><strong>P5</strong></TableCell>
                                        <TableCell align="center"><strong>P6</strong></TableCell>
                                        <TableCell align="center"><strong>P7</strong></TableCell>
                                        <TableCell align="center"><strong>P8</strong></TableCell>
                                        <TableCell align="center"><strong>P9</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center">Yes</TableCell>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">Yes</TableCell>
                                        <TableCell align="center">Yes</TableCell>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">Yes</TableCell>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">No</TableCell>
                                        <TableCell align="center">No</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Typography mb={5} variant='body1'>
                        KPMG conducts assessment of Health & Safety, Governance practices of the company.
                    </Typography>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            12. If all Principles are not covered by a policy, reasons to be stated.
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Questions</strong></TableCell>
                                        <TableCell align="center"><strong>P1</strong></TableCell>
                                        <TableCell align="center"><strong>P2</strong></TableCell>
                                        <TableCell align="center"><strong>P3</strong></TableCell>
                                        <TableCell align="center"><strong>P4</strong></TableCell>
                                        <TableCell align="center"><strong>P5</strong></TableCell>
                                        <TableCell align="center"><strong>P6</strong></TableCell>
                                        <TableCell align="center"><strong>P7</strong></TableCell>
                                        <TableCell align="center"><strong>P8</strong></TableCell>
                                        <TableCell align="center"><strong>P9</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">The entity does not consider the Principles material to its business (Yes/No)</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">The entity is not at a stage where it is in a position to formulate and implement the policies on specified principles (Yes/No)</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">The entity does not have the financial or/human and technical resources available for the task (Yes/No)</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                        <TableCell align='center'>No</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">It is planned to be done in the next financial year (Yes/No) </TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                        <TableCell align='center'>Yes</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Any other reason (please specify)</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionb;