import React from 'react';
import Counter from '../ReusableComponents/counter';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im16 from '../../images/SDG/16.jpg';
import section1 from '../../images/Banners/section1.webp'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupsIcon from '@mui/icons-material/Groups';

const counterData = [
    {
        "num": 100,
        "label": "of Key Managerial personnel covered by the awareness programmes",
        "icon": GroupsIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 0,
        "label": "Does the entity have an anti-corruption or anti-bribery policy ?",
        "icon": CheckCircleOutlineIcon,
        "prefix": "Yes",
        "isdecimal": false,
        "suffix": ""
    },
    {
        "num": 0,
        "label": "Does the entity have processes in place to manage conflict of interests involving members of the Board?",
        "icon": CheckCircleOutlineIcon,
        "prefix": "Yes",
        "isdecimal": false,
        "suffix": ""
    },
]

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(2);
  }
  `}
`;

const Sectionc1 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true); setExpanded1(true); setExpanded2(true); setExpanded3(true); setExpanded4(true); setExpanded5(true); setExpanded6(true); setExpanded7(true); setExpanded8(true); setExpanded9(true);
    };
    const closeAll = () => {
        setExpand(false); setExpanded1(false); setExpanded2(false); setExpanded3(false); setExpanded4(false); setExpanded5(false); setExpanded6(false); setExpanded7(false); setExpanded8(false); setExpanded9(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 1
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Integrity and Ethics
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should conduct and govern themselves with integrity, and in a manner that is Ethical, Transparent and Accountable
                        </Typography>
                    </Box>
                    <Box mt={3} justifyContent='space-between' sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im16} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section1})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box px={4} py={6} mt={8} sx={{ backgroundColor: '#f7f7ef', borderRadius: '8px' }}>
                <Box mb={6} display="flex" justifyContent="center">
                    <Typography variant='h4' sx={{ color: "#67725d", fontSize: "34px", fontWeight: "900" }}>
                        Highlights
                    </Typography>
                </Box>
                <Box>
                    <Counter data={counterData} />
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Percentage covered by training and awareness programmes on any of the principles during the financial year:
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Segment</strong></TableCell>
                                        <TableCell align="center" ><strong>Total number of training and awareness programs held</strong></TableCell>
                                        <TableCell align="center" ><strong>Topics/principles covered under the training and its impact</strong></TableCell>
                                        <TableCell align="center" ><strong>Percentage of persons in respective category covered by the awareness programmes</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Board of directors
                                        </TableCell>
                                        <TableCell align='center'>5</TableCell>
                                        <TableCell align='center'>ESG</TableCell>
                                        <TableCell align='center'>33%</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Key Managerial personnel
                                        </TableCell>
                                        <TableCell align='center' >2</TableCell>
                                        <TableCell align='center'>POSH, ABAC </TableCell>
                                        <TableCell align='center'>100%</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Employees other than BoD and KMPs
                                        </TableCell>
                                        <TableCell align='center'>10</TableCell>
                                        <TableCell align='center'>IT Awareness, SA 8000- IA, IMS -IA, POSH,
                                            ABAC, Insider Trading Email Etiquettes,
                                            Communication Skill, </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Workers(Contractual)
                                        </TableCell>
                                        <TableCell align='center'>Professional Training -280
                                            ESG Training -633 </TableCell>
                                        <TableCell align="center">Professional & ESG Training</TableCell>
                                        <TableCell align="center">-</TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Details of fines / penalties / punishment / award / compounding fees / settlement amount paid in proceedings (by the entity or by directors / KMPs) with regulators/ law enforcement agencies/ judicial
                            institutions, in the financial year, in the following format (Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of SEBI (Listing Obligations and Disclosure Obligations)
                            Regulations, 2015 and as disclosed on the entity’s website)
                            <ExpandMore
                                expand={expanded2}
                                onClick={handleExpandClick2}
                                aria-expanded={expanded2}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={2}>
                        <Typography variant='body1'>
                            <strong>(a) Monetary</strong>
                        </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center" ><strong>Category</strong></TableCell>
                                    <TableCell align="center" ><strong>NGRBC Principle</strong></TableCell>
                                    <TableCell align="center" ><strong>Name of the regulatory/
                                        enforcement agencies/
                                        judicial institutions
                                    </strong></TableCell>
                                    <TableCell align="center" ><strong>Amount (In INR)
                                    </strong></TableCell>
                                    <TableCell align="center" ><strong>Brief of the Case
                                    </strong></TableCell>
                                    <TableCell align="center" ><strong>Has an appeal been
                                        preferred? (Yes/No)
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Penalty/ Fine
                                    </TableCell>
                                    <TableCell align="center" rowSpan={3} colSpan={5}>- </TableCell>

                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Settlement
                                    </TableCell>

                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Compounding fee
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={2} mt={4}>
                        <Typography variant='body1'>
                            <strong>(b) Non-Monetary</strong>
                        </Typography>
                    </Box>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Category</strong></TableCell>
                                        <TableCell align="center" ><strong>NGRBC Principle</strong></TableCell>
                                        <TableCell align="center" ><strong>Name of the regulatory/
                                            enforcement agencies/
                                            judicial institutions
                                        </strong></TableCell>

                                        <TableCell align="center"><strong>Brief of the Case
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Has an appeal been
                                            preferred? (Yes/No)
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Imprisonment
                                        </TableCell>
                                        <TableCell align="center" rowSpan={3} colSpan={5}>- </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Punishment
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where monetary or non-monetary action has been appealed.
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Case Details
                                            held</strong></TableCell>
                                        <TableCell align="center" ><strong>Name of the regulatory/ enforcement agencies/ judicial institutions
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                        <TableCell component="th" scope="row" align='center'>Not applicable</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Does the entity have an anti-corruption/ anti-bribery policy? If yes, provide details in brief and if available, provide a web link to the policy.
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Anti-bribery and Corruption policy is in place, and can be referred at: <br /> <a href='https://virescent.co.in/wp-content/uploads/2022/12/V3_ABC-Policy_28_12_22.pdf' target="_blank" rel="noopener noreferrer">https://virescent.co.in/wp-content/uploads/2022/12/V3_ABC-Policy_28_12_22.pdf</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption:
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Category</strong></TableCell>
                                        <TableCell align="center" ><strong>FY 2022-23</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Directors
                                        </TableCell>
                                        <TableCell align="center" rowSpan={4}>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            KMPs
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Employees
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Workers
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            6. Details of complaints with regard to conflict of interest:
                            <ExpandMore
                                expand={expanded6}
                                onClick={handleExpandClick6}
                                aria-expanded={expanded6}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Topic</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>FY 2022-23 <br />(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Number</strong></TableCell>
                                        <TableCell align="center" ><strong>Remarks</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Number of complaints received in relation to issues of Conflict of Interest of the Directors
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2} colSpan={4}>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Number of complaints received in relation to issues of Conflict of Interest of the KMPs
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            7. Provide details of any corrective action taken or underway on issues related to fines / penalties / action taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and
                            conflicts of interest.
                            <ExpandMore
                                expand={expanded7}
                                onClick={handleExpandClick7}
                                aria-expanded={expanded7}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Awareness programmes conducted for value chain partners on any of the Principles during the financial year:
                            <ExpandMore
                                expand={expanded8}
                                onClick={handleExpandClick8}
                                aria-expanded={expanded8}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Total number of training and
                                            awareness programmes held</strong></TableCell>
                                        <TableCell align="center" ><strong>Topics/principles covered under the training and its impact</strong></TableCell>
                                        <TableCell align="center" ><strong>%age of persons in value chain covered by the
                                            awareness programmes </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            1
                                        </TableCell>
                                        <TableCell align='center'>Training programmes conducted at Plant includes all the O&M contract workers</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board? (Yes/No) If Yes, provide details of the same.
                            <ExpandMore
                                expand={expanded9}
                                onClick={handleExpandClick9}
                                aria-expanded={expanded9}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Yes. Related Party Transactions Policy is available at <a href='https://virescent.co.in/wp-content/uploads/2022/03/V4_RPT-Policy-IM_02_03_22.pdf' target="_blank" rel="noopener noreferrer">https://virescent.co.in/wp-content/uploads/2022/03/V4_RPT-Policy-IM_02_03_22.pdf</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc1;