import React from 'react';
import { createElement } from 'react';

//MUI
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

//Count up
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';



const Counter = ({ data }) => {

    return (
        <Grid container columnSpacing={3} rowSpacing={6} justifyContent="center">
            {data.map((d, idx) => (
                <Grid item xs={12} sm={6} lg={4} key={idx}>
                    <Box display="flex" justifyContent="start">
                        <Box mr={2}>
                            <Avatar sx={{ width: 52, height: 52, padding: '8px', backgroundColor: '#ffffff' }}>
                                {createElement(d.icon, { sx: { fontSize: 36, color: '#5a6054' } })}
                            </Avatar>
                        </Box>
                        <Box>
                            <Box display="flex" justifyContent="start" alignItems="end">
                                <Typography variant='h4' sx={{ marginBottom: "10px", marginRight: "10px", fontWeight: "bold", color: "#133346" }}>
                                    {d.prefix}
                                    {d.num !== 0 &&
                                        <CountUp end={d.num} redraw={true} duration={1}
                                            decimals={d.isdecimal ? 2 : 0}
                                            decimal={d.isdecimal ? "." : null}
                                            separator=","
                                        >
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} delayedCall>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                    }
                                </Typography>
                                <Typography variant='h6' sx={{ marginBottom: "10px", fontWeight: "bold", color: "#133346" }}>
                                    {d.suffix}
                                </Typography>
                            </Box>
                            <Typography variant='h6' sx={{ fontWeight: "lighter", color: "#133346" }}>
                                {d.label}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}

export default Counter;