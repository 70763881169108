import React from 'react';
import Counter from '../ReusableComponents/counter';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im2 from '../../images/SDG/2.jpg';
import im3 from '../../images/SDG/3.jpg';
import im5 from '../../images/SDG/5.jpg';
import im6 from '../../images/SDG/6.jpg';
import im7 from '../../images/SDG/7.jpg';
import im8 from '../../images/SDG/8.jpg';
import im9 from '../../images/SDG/9.jpg';
import im10 from '../../images/SDG/10.jpg';
import im11 from '../../images/SDG/11.jpg';
import im12 from '../../images/SDG/12.jpg';
import im13 from '../../images/SDG/13.jpg';
import im14 from '../../images/SDG/14.jpg';
import im15 from '../../images/SDG/15.jpg';
import im16 from '../../images/SDG/16.jpg';
import section2 from '../../images/Banners/section2.webp'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';



const counterData = [
    {
        "num": 2.5,
        "label": "Capex Investments in PV Panel cleaning mechanism",
        "icon": CurrencyRupeeIcon,
        "prefix": "",
        "isdecimal": true,
        "suffix": "Crore"
    },
    {
        "num": 6068959,
        "label": "Capex Investments in Rainwater harvesting projects",
        "icon": CurrencyRupeeIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": ""
    },
]

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(2);
  }
  `}
`;


const Sectionc2 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expanded10, setExpanded10] = React.useState(false);
    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true);
        setExpanded1(true);
        setExpanded2(true);
        setExpanded4(true);
        setExpanded5(true);
        setExpanded6(true);
        setExpanded7(true);
        setExpanded8(true);
        setExpanded9(true);
        setExpanded10(true);
    };
    const closeAll = () => {
        setExpand(false);
        setExpanded1(false);
        setExpanded2(false);
        setExpanded4(false);
        setExpanded5(false);
        setExpanded6(false);
        setExpanded7(false);
        setExpanded8(false);
        setExpanded9(false);
        setExpanded10(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 2
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Sustainable Product and Services
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should provide goods and services in a manner that is  sustainable and safe
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im2} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im3} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im5} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im6} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im7} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im8} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im9} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im10} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im11} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im12} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im13} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im14} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im15} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im16} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section2})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box px={4} py={6} mt={8} sx={{backgroundColor: '#f7f7ef', borderRadius: '8px'}}>
                <Box mb={6} display="flex" justifyContent="center">
                    <Typography variant='h4' sx={{ color: "#67725d", fontSize: "34px", fontWeight: "900" }}>
                        Highlights
                    </Typography>
                </Box>
                <Box>
                    <Counter data={counterData} />
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve the environmental and social impacts of product and processes to total R&D and capex investments
                            made by the entity, respectively.
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Category</strong></TableCell>
                                        <TableCell align="center" ><strong>FY 2022-23</strong></TableCell>
                                        <TableCell align="center" ><strong>FY 2021-22</strong></TableCell>
                                        <TableCell align="center" ><strong>Details of improvements in environmental and social impacts</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Research & Development (R&D)
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell >NA</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Capital Expenditure (CAPEX)
                                        </TableCell>
                                        <TableCell>Rs.86,95,332<br></br><br></br>
                                            Rs. 2.5 cr (capex) +<br></br>
                                            40,00,000 (opex)<br></br><br></br>
                                            Rs. 60,68,959</TableCell>
                                        <TableCell>Rs.9,34,480</TableCell>
                                        <TableCell>Expenditure pertains to procurement of Inverters / Data
                                            processing equipment<br></br><br></br>
                                            PV Panel cleaning mechanism - Robotic cleaning
                                            installation<br></br><br></br>
                                            Rainwater harvesting projects (Root top and borewell) at
                                            all sites</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. a. Does the entity have procedures in place for sustainable sourcing? (Yes/No)
                            <ExpandMore
                                expand={expanded2}
                                onClick={handleExpandClick2}
                                aria-expanded={expanded2}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            No
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the end of life, for (a) Plastics (including packaging) (b) E-waste (c) Hazardous waste and (d) other
                            waste.

                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Our end product is energy hence reclaim is not applicable.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No). If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan
                            submitted to Pollution Control Boards? If not, provide steps taken to address the same.
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not Applicable.

                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for manufacturing industry) or for its services (for service industry)? If yes, provide details in the following
                            format?

                            <ExpandMore
                                expand={expanded6}
                                onClick={handleExpandClick6}
                                aria-expanded={expanded6}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>NIC Code </strong></TableCell>
                                        <TableCell align="center" ><strong>Name of
                                            Product/Service</strong></TableCell>
                                        <TableCell align="center" ><strong>% of total Turnover
                                            contributed
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Boundary for the Life Cycle
                                            Perspective/Assessment was
                                            conducted
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Whether conducted by
                                            independent external
                                            agency (Yes/No)
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Results communicated in public
                                            domain (Yes/No)
                                            If yes, provide the web-link.
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. If there are any significant social or environmental concerns and/or risks arising from production or disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA) or
                            through any other means, briefly describe the same along-with action taken to mitigate the same.

                            <ExpandMore
                                expand={expanded7}
                                onClick={handleExpandClick7}
                                aria-expanded={expanded7}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>S,No. </strong></TableCell>
                                        <TableCell align="center" ><strong>Name of the
                                            product</strong></TableCell>
                                        <TableCell align="center" ><strong>Description of the risk
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Action Taken
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Percentage of recycled or reused input material to total material (by value) used in production (for manufacturing industry) or providing services (for service industry).
                            <ExpandMore
                                expand={expanded8}
                                onClick={handleExpandClick8}
                                aria-expanded={expanded8}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Indicate input material</strong></TableCell>
                                        <TableCell align="center"><strong>Recycled or re-used input material to total material</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align='center'>
                                            <strong>FY 2022-23</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Of the products and packaging reclaimed at end of life of products, amount (in metric tons) reused, recycled, and safely disposed, as per the following format:


                            <ExpandMore
                                expand={expanded9}
                                onClick={handleExpandClick9}
                                aria-expanded={expanded9}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2022-23</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Reused</strong></TableCell>
                                        <TableCell align="center" ><strong>Recycled</strong></TableCell>
                                        <TableCell align="center" ><strong>Safely
                                            Disposed
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Plastics (including packaging)
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            E-waste
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Hazardous waste
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Other waste
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Reclaimed products and their packaging materials (as percentage of products sold) for each product category.
                            <ExpandMore
                                expand={expanded10}
                                onClick={handleExpandClick10}
                                aria-expanded={expanded10}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded10} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>Indicate product category </strong></TableCell>
                                        <TableCell align="center" ><strong>Reclaimed products and their packaging materials as % of total products sold in
                                            respective category</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc2;