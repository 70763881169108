import { Route, Routes } from "react-router-dom";
import Home from './components/home';
import SASB from "./components/sasb";
import BRSR from "./components/brsr";
import Sectiona from "./components/sections/sectiona";
import Sectionb from "./components/sections/sectionb";
import Sectionc1 from "./components/sections/sectionc1";
import Sectionc2 from "./components/sections/sectionc2";
import Sectionc3 from "./components/sections/sectionc3";
import Sectionc4 from "./components/sections/sectionc4";
import Sectionc5 from "./components/sections/sectionc5";
import Sectionc6 from "./components/sections/sectionc6";
import Sectionc7 from "./components/sections/sectionc7";
import Sectionc8 from "./components/sections/sectionc8";
import Sectionc9 from "./components/sections/sectionc9";
import Navbar from "./components/navbar";

import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#67725d',
    }
  },
});


function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/sasb-metrics" element={<SASB />} exact />
          <Route path="/brsr" element={<BRSR />} exact />
          <Route path="/sectiona" element={<Sectiona />} exact />
          <Route path="/sectionb" element={<Sectionb />} exact />
          <Route path="/sectionc1" element={<Sectionc1 />} exact />
          <Route path="/sectionc2" element={<Sectionc2 />} exact />
          <Route path="/sectionc3" element={<Sectionc3 />} exact />
          <Route path="/sectionc4" element={<Sectionc4 />} exact />
          <Route path="/sectionc5" element={<Sectionc5 />} exact />
          <Route path="/sectionc6" element={<Sectionc6 />} exact />
          <Route path="/sectionc7" element={<Sectionc7 />} exact />
          <Route path="/sectionc8" element={<Sectionc8 />} exact />
          <Route path="/sectionc9" element={<Sectionc9 />} exact />
        </Routes>
      </ThemeProvider>
    </>

  );
}

export default App;
