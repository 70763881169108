import React from 'react';
import Counter from '../ReusableComponents/counter';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im1 from '../../images/SDG/1.jpg';
import im3 from '../../images/SDG/3.jpg';
import im4 from '../../images/SDG/4.jpg';
import im5 from '../../images/SDG/5.jpg';
import im8 from '../../images/SDG/8.jpg';
import im10 from '../../images/SDG/10.jpg';
import im11 from '../../images/SDG/11.jpg';
import im16 from '../../images/SDG/16.jpg';
import section3 from '../../images/Banners/section3.webp'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import BusinessIcon from '@mui/icons-material/Business';



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const counterData = [
    {
        "num": 100,
        "label": "Health Insurance for Permanent Employees",
        "icon": MedicalInformationIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "Accident Insurance for Permanent Employees",
        "icon": MedicalInformationIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "Maternity benefits for all Female Employees",
        "icon": PregnantWomanIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "PF and Gratuity for Employees",
        "icon": CreditScoreIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "Return to work rate for permanent employees",
        "icon": BusinessIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "Of plants and offices assessed on Health & Safety and Working Conditions",
        "icon": CheckCircleOutlineIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
]

const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(1.8);
  }
  `}
`;



const Sectionc3 = () => {

    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };


    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expanded10, setExpanded10] = React.useState(false);
    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    };

    const [expanded12, setExpanded12] = React.useState(false);
    const handleExpandClick12 = () => {
        setExpanded12(!expanded12);
    };

    const [expanded15, setExpanded15] = React.useState(false);
    const handleExpandClick15 = () => {
        setExpanded15(!expanded15);
    };

    const [expanded16, setExpanded16] = React.useState(false);
    const handleExpandClick16 = () => {
        setExpanded16(!expanded16);
    };

    const [expanded17, setExpanded17] = React.useState(false);
    const handleExpandClick17 = () => {
        setExpanded17(!expanded17);
    };

    const [expanded18, setExpanded18] = React.useState(false);
    const handleExpandClick18 = () => {
        setExpanded18(!expanded18);
    };

    const [expanded19, setExpanded19] = React.useState(false);
    const handleExpandClick19 = () => {
        setExpanded19(!expanded19);
    };

    const [expanded20, setExpanded20] = React.useState(false);
    const handleExpandClick20 = () => {
        setExpanded20(!expanded20);
    };

    const [expanded21, setExpanded21] = React.useState(false);
    const handleExpandClick21 = () => {
        setExpanded21(!expanded21);
    };

    const [expanded22, setExpanded22] = React.useState(false);
    const handleExpandClick22 = () => {
        setExpanded22(!expanded22);
    };

    const [expanded23, setExpanded23] = React.useState(false);
    const handleExpandClick23 = () => {
        setExpanded23(!expanded23);
    };

    const [expanded24, setExpanded24] = React.useState(false);
    const handleExpandClick24 = () => {
        setExpanded24(!expanded24);
    };

    const [expanded25, setExpanded25] = React.useState(false);
    const handleExpandClick25 = () => {
        setExpanded25(!expanded25);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true); setExpanded1(true); setExpanded2(true); setExpanded3(true); setExpanded4(true); setExpanded5(true); setExpanded6(true); setExpanded7(true); setExpanded8(true); setExpanded9(true); setExpanded10(true); setExpanded12(true); setExpanded15(true); setExpanded16(true); setExpanded17(true); setExpanded18(true); setExpanded19(true); setExpanded20(true); setExpanded21(true); setExpanded22(true); setExpanded23(true); setExpanded24(true); setExpanded25(true);
    };
    const closeAll = () => {
        setExpand(false); setExpanded1(false); setExpanded2(false); setExpanded3(false); setExpanded4(false); setExpanded5(false); setExpanded6(false); setExpanded7(false); setExpanded8(false); setExpanded9(false); setExpanded10(false); setExpanded12(false); setExpanded15(false); setExpanded16(false); setExpanded17(false); setExpanded18(false); setExpanded19(false); setExpanded20(false); setExpanded21(false); setExpanded22(false); setExpanded23(false); setExpanded24(false); setExpanded25(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 3
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Employees Wellbeing
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should respect and promote the well-being of all employees,
                            including those in their value chains
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox> <img src={im1} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im3} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im4} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im5} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im8} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im10} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im11} height="40" alt="svg" /></StyledBox>
                        <StyledBox> <img src={im16} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section3})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box px={4} py={6} mt={8} sx={{ backgroundColor: '#f7f7ef', borderRadius: '8px' }}>
                <Box mb={6} display="flex" justifyContent="center">
                    <Typography variant='h4' sx={{ color: "#67725d", fontSize: "34px", fontWeight: "900" }}>
                        Highlights
                    </Typography>
                </Box>
                <Box>
                    <Counter data={counterData} />
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. a. Details of measures for the well-being of employees
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align='center' rowSpan={3}><strong>Category</strong></TableCell>
                                        <TableCell align="center" colSpan={11}><strong>% of employees covered by</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align='center' rowSpan={2}><strong>Total (A) </strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Health insurance</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Accident insurance</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Maternity benefits</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Paternity Benefits</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Day Care facilities</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>No. (B) </strong></TableCell>
                                        <TableCell align="center"><strong>% (B / A)</strong></TableCell>
                                        <TableCell align="center"><strong>No. (C)</strong></TableCell>
                                        <TableCell align="center"><strong>% (C /
                                            A)</strong></TableCell>
                                        <TableCell align="center"><strong>No.
                                            (D)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>% (D /
                                            A)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>No.
                                            (E)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>% (E /
                                            A)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>No.
                                            (F)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>% (F /
                                            A)
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550, backgroundColor: "#e0e0e0" }} colSpan={12} align="center">
                                            <strong>Permanent Employees</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Male
                                        </TableCell>
                                        <TableCell align="center">91 </TableCell>
                                        <TableCell align="center">91 </TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">91</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">91 </TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Female
                                        </TableCell>
                                        <TableCell align="center">7</TableCell>
                                        <TableCell align="center">7</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">7</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">7</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">98</TableCell>
                                        <TableCell align="center">98</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">98</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">7</TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">91 </TableCell>
                                        <TableCell align="center">100%</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550, backgroundColor: "#e0e0e0" }} colSpan={12} align="center">
                                            <strong>Other than permanent Employees</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Male
                                        </TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Female
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        1. b. Details of measures for the well-being of workers.
                        <ExpandMore
                            expand={expanded2}
                            onClick={handleExpandClick2}
                            aria-expanded={expanded2}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align='center' rowSpan={3}><strong>Category</strong></TableCell>
                                        <TableCell align="center" colSpan={11}><strong>% of workers covered by</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align='center' rowSpan={2}><strong>Total (A) </strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Health insurance</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Accident insurance</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Maternity benefits</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Paternity Benefits</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Day Care facilities</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>No. (B) </strong></TableCell>
                                        <TableCell align="center"><strong>% (B / A)</strong></TableCell>
                                        <TableCell align="center"><strong>No. (C)</strong></TableCell>
                                        <TableCell align="center"><strong>% (C /
                                            A)</strong></TableCell>
                                        <TableCell align="center"><strong>No.
                                            (D)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>% (D /
                                            A)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>No.
                                            (E)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>% (E /
                                            A)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>No.
                                            (F)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>% (F /
                                            A)
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550, backgroundColor: "#e0e0e0" }} colSpan={12} align="center">
                                            <strong>Permanent Workers</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Male
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Female
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ width: 550, backgroundColor: "#e0e0e0" }} colSpan={12} align="center">
                                            <strong>Other than permanent Workers</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Male
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Female
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">- </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Details of retirement benefits, for Current FY and Previous Financial Year.
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Benefits</strong></TableCell>
                                        <TableCell align='center' colSpan={3}><strong>FY 2022-23<br></br>
                                            (Current Financial Year)</strong>
                                        </TableCell>
                                        <TableCell align='center' colSpan={3}><strong>FY 2021-22<br></br>
                                            (Previous FinancialYear)</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" ><strong>No. of employees covered as a
                                            % of total employees.
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>No. of workers covered as a %
                                            of total workers.
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Deducted and deposited with
                                            the authority (Y/N/N.A.).
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>No. of employees covered as a
                                            % of total employees.
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>No. of workers covered as a %
                                            of total workers.
                                        </strong></TableCell>
                                        <TableCell align="center" ><strong>Deducted and deposited with
                                            the authority (Y/N/N.A.).
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            PF
                                        </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center' rowSpan={4}>Contractual
                                            workers are
                                            provided with
                                            benefits by
                                            contractors. </TableCell>
                                        <TableCell align='center'>Y</TableCell>
                                        <TableCell align='center'>99%</TableCell>
                                        <TableCell align='center' rowSpan={4}>Contractual
                                            workers are
                                            provided with
                                            benefits by
                                            contractors.</TableCell>
                                        <TableCell align='center'>Y</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Gratuity
                                        </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>N</TableCell>
                                        <TableCell align='center'>100%</TableCell>
                                        <TableCell align='center'>N</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            ESI
                                        </TableCell>
                                        <TableCell align='center'>2% </TableCell>
                                        <TableCell align='center'>Y</TableCell>
                                        <TableCell align='center'>1%</TableCell>
                                        <TableCell align='center'>Y</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Others - please specify
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Are the premises / offices of the entity accessible to differently abled employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not, whether any steps are
                            being taken by the entity in this regard.
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Our corporate offices have lift and ramps to facilitate access.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If so, provide a web-link to the policy.
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Yes. The relevant clauses are included in HR policy.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Return to work and Retention rates of permanent employees and workers that took parental leave.
                            <ExpandMore
                                expand={expanded6}
                                onClick={handleExpandClick6}
                                aria-expanded={expanded6}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Gender</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Permanent employees
                                        </strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Contractual employees
                                        </strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Return-to-work rate FY 23
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Retention rate FY 22
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Return-to-work rate FY 23
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Retention rate FY 22
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Male
                                        </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>NA </TableCell>
                                        <TableCell align='center'>NA </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Female
                                        </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>NA </TableCell>
                                        <TableCell align='center'>NA </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Others
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Total
                                        </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>100% </TableCell>
                                        <TableCell align='center'>NA </TableCell>
                                        <TableCell align='center'>NA </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            6. Is there a mechanism available to receive and redress grievances for the following categories of employees and worker? If yes, give details of the mechanism in brief.
                            <ExpandMore
                                expand={expanded7}
                                onClick={handleExpandClick7}
                                aria-expanded={expanded7}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Category</strong></TableCell>
                                        <TableCell align="center" ><strong>Yes/No (If Yes, then give details of the mechanism in brief)
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Permanent Workers
                                        </TableCell>
                                        <TableCell rowSpan={4}>
                                            Yes. Grievance redressal forms part of HR policy. In case of O&M workers, Complaint register is maintained
                                            at plant, however, no complaints are registered yet.
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Other than permanent worker
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Permanent Employees
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Other than permanent Employees
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            7. Membership of employees and worker in association(s) or Unions recognised by the listed entity:
                            <ExpandMore
                                expand={expanded8}
                                onClick={handleExpandClick8}
                                aria-expanded={expanded8}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper} m>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center" rowSpan={2}><strong>Category</strong></TableCell>
                                    <TableCell align="center" colSpan={3}><strong>FY 2022-23 <br />(Current Financial Year)
                                    </strong></TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>Total employees / workers
                                        in respective category
                                        (A)
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>No. of employees / workers in
                                        respective category, who are
                                        part of association(s) or
                                        Union
                                        (B)
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>% (B/A)
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" colSpan={7} sx={{ backgroundColor: "#e0e0e0" }}>
                                        <strong>Permanent Employees</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Male
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Female
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Other
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" colSpan={7} sx={{ backgroundColor: "#e0e0e0" }}>
                                        <strong>Permanent Workers</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Male
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Female
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Other
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                        <Typography variant='body1'>
                            Note - No employees are associated with any unions.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            8. Details of training given to employees and workers:
                            <ExpandMore
                                expand={expanded9}
                                onClick={handleExpandClick9}
                                aria-expanded={expanded9}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper} m>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center" rowSpan={3}><strong>Category</strong></TableCell>
                                    <TableCell align="center" colSpan={5}><strong>FY 2022-23 <br /> (Current Financial Year)
                                    </strong></TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center" rowSpan={2}><strong>Total
                                    </strong></TableCell>
                                    <TableCell align="center" colSpan={2}><strong>On Health and safety measures
                                    </strong></TableCell>
                                    <TableCell align="center" colSpan={2}><strong>On Skill upgradation
                                    </strong></TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>No.
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>%
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>No. </strong></TableCell>
                                    <TableCell align="center"><strong>%
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" colSpan={11} align="center" sx={{ backgroundColor: "#e0e0e0" }}>
                                        <strong>Employees & Workers</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Male
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Female
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            9. Details of performance and career development reviews of employees and workers
                            <ExpandMore
                                expand={expanded10}
                                onClick={handleExpandClick10}
                                aria-expanded={expanded10}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded10} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper} m>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center" rowSpan={2}><strong>Category</strong></TableCell>
                                    <TableCell align="center" colSpan={3}><strong>FY 2022-23
                                    </strong></TableCell>
                                    <TableCell align="center" colSpan={3}><strong>FY 2021-22
                                    </strong></TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>Total employees /
                                        workers in
                                        respective category (A)
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>No. of employees /
                                        workers in
                                        respective category, who
                                        had a career review (B)
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>% (B / A) </strong></TableCell>
                                    <TableCell align="center"><strong>Total employees /
                                        workers in
                                        respective category(C)
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>No. of employees /
                                        workers in
                                        respective category,
                                        who had a career review
                                        (D)

                                    </strong></TableCell>
                                    <TableCell align="center"><strong>% (D / C)
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" colSpan={7} align="center" sx={{ backgroundColor: "#e0e0e0" }}>
                                        <strong>Employees</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Male
                                    </TableCell>
                                    <TableCell align='center'>91</TableCell>
                                    <TableCell align='center'>87*
                                    </TableCell>
                                    <TableCell align='center'>97%
                                    </TableCell>
                                    <TableCell align='center'>91</TableCell>
                                    <TableCell align='center'>79
                                    </TableCell>
                                    <TableCell align='center'>89%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Female
                                    </TableCell>
                                    <TableCell align='center'>7</TableCell>
                                    <TableCell align='center'>7
                                    </TableCell>
                                    <TableCell align='center'>100%
                                    </TableCell>
                                    <TableCell align='center'>6</TableCell>
                                    <TableCell align='center'>5
                                    </TableCell>
                                    <TableCell align='center'>83%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align='center'>98</TableCell>
                                    <TableCell align='center'>94*
                                    </TableCell>
                                    <TableCell align='center'>96%
                                    </TableCell>
                                    <TableCell align='center'>97</TableCell>
                                    <TableCell align='center'>84
                                    </TableCell>
                                    <TableCell align='center'>88%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" colSpan={7} align="center" sx={{ backgroundColor: "#e0e0e0" }}>
                                        <strong>Contractual</strong>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Male
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-
                                    </TableCell>
                                    <TableCell align='center'>-                                   </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-
                                    </TableCell>
                                    <TableCell align='center'>-                                   </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Female
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-
                                    </TableCell>
                                    <TableCell align='center'>-                                   </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-
                                    </TableCell>
                                    <TableCell align='center'>-                                   </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Total
                                    </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-
                                    </TableCell>
                                    <TableCell align='center'>-                                   </TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-
                                    </TableCell>
                                    <TableCell align='center'>-                                   </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                        <Typography variant='body1'>
                            *The employees who joined before December 2022 are only considered for career development review.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            10. Health and safety management system
                            <ExpandMore
                                expand={expanded12}
                                onClick={handleExpandClick12}
                                aria-expanded={expanded12}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded12} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ backgroundColor: "#e0e0e0" }}>
                                            <strong>
                                                a. Whether an occupational health and safety management system has been
                                                implemented by the entity? (Yes/No)
                                            </strong>
                                        </TableCell>
                                        <TableCell rowSpan={5}>
                                            Yes, it is implemented across the entities for which VRET has received ISO certification
                                            like ISO 9001, ISO 140001, ISO 31000, and ISO 45000.
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ backgroundColor: "#e0e0e0" }}>
                                            <strong>
                                                a.1What is the coverage of such system?
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ backgroundColor: "#e0e0e0" }}>
                                            <strong>
                                                b. What are the processes used to identify work-related hazards and assess risks on a
                                                routine and non-routine basis by the entity?
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ backgroundColor: "#e0e0e0" }}>
                                            <strong>
                                                c. Whether you have processes for workers to report the work related hazards and to
                                                remove themselves from such risks. (Yes/No)
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" sx={{ backgroundColor: "#e0e0e0" }}>
                                            <strong>
                                                d. Do the employees/ worker of the entity have access to non-occupational medical
                                                and healthcare services? (Yes/ No)
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            11. Details of safety related incidents, in the following format:
                            <ExpandMore
                                expand={expanded15}
                                onClick={handleExpandClick15}
                                aria-expanded={expanded15}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded15} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper} m>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>Safety Incident/Number</strong></TableCell>
                                    <TableCell align="center"><strong>Category</strong></TableCell>
                                    <TableCell align="center"><strong>FY 2022-23<br /> (Current Financial Year)
                                    </strong></TableCell>
                                    <TableCell align="center"><strong>FY 2021-22<br /> (Previous Financial Year)
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" rowSpan={2}>
                                        Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)
                                    </TableCell>
                                    <TableCell align='center'>Employees</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align='center'>Workers</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" rowSpan={2}>
                                        Total recordable work-related injuries
                                    </TableCell>
                                    <TableCell align='center'>Employees</TableCell>
                                    <TableCell align='center'>1</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align='center'>Workers</TableCell>
                                    <TableCell align='center'>1</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" rowSpan={2}>
                                        No. of fatalities
                                    </TableCell>
                                    <TableCell align='center'>Employees</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align='center'>Workers</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" rowSpan={2} >
                                        High consequence work-related injury or ill-health (excluding fatalities)
                                    </TableCell>
                                    <TableCell align='center'>Employees</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align='center'>Workers</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                    <TableCell align='center'>-</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                        <Typography variant='body1'>
                            We have policies and mechanism in place for well-being of all our employees including contractors (O&M Contractors and their sub-contractors as value chains).
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            12. Describe the measures taken by the entity to ensure a safe and healthy work place.
                            <ExpandMore
                                expand={expanded16}
                                onClick={handleExpandClick16}
                                aria-expanded={expanded16}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded16} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            (a). ISO certified EHS Management system extended and practiced at all operational project sites.<br></br>
                            (b). PPEs and Training imparted to all sites.<br></br>
                            (c). Mock drills & audits
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            13. Number of complaints on the following made by employees and workers
                            <ExpandMore
                                expand={expanded17}
                                onClick={handleExpandClick17}
                                aria-expanded={expanded17}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded17} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2022-23<br /> (Current Financial Year)
                                        </strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2021-22<br /> (Previous Financial Year)
                                        </strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Filed during the year
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Pending resolution at the end of year
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Remarks</strong></TableCell>
                                        <TableCell align="center"><strong>Filed during the year
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Pending resolution at the end of year
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Remarks</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Working Conditions
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Health & Safety
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            14. Assessments for the year:
                            <ExpandMore
                                expand={expanded18}
                                onClick={handleExpandClick18}
                                aria-expanded={expanded18}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded18} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper} m>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>Category</strong></TableCell>
                                    <TableCell align="center" ><strong>% of your plants and offices that were assessed (by entity or statutory authorities or third parties)
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Health and safety practices
                                    </TableCell>
                                    <TableCell align='center'>100%
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Working Conditions
                                    </TableCell>
                                    <TableCell align='center'>100%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                        <Typography variant='body1'>
                            <ul>
                                <li>Internal audit was carried out by KPMG team. </li>
                                <li>TUV-Nord certification agency conducted audits at our sites for ISO 9001, ISO14001, ISO45001 and ISO 31000 standards.</li>
                            </ul>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            15. Provide details of any corrective action taken or underway to address safety-related incidents (if any) and on significant risks / concerns arising from assessments of health & safety practices and
                            working conditions.
                            <ExpandMore
                                expand={expanded19}
                                onClick={handleExpandClick19}
                                aria-expanded={expanded19}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded19} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Accident Incident reporting and Investigation SOP is in place. All the safety related incidents are investigated properly as per the defined process. All the identified CAPA
                            actions are defined and implemented at all sites to ensure the restriction of reoccurrence of same type of incident. Accident incident report shared with all team members.
                            Alert circulated to all site team members
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees (Y/N) (B) Workers (Y/N).
                            <ExpandMore
                                expand={expanded20}
                                onClick={handleExpandClick20}
                                aria-expanded={expanded20}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded20} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            The Company is providing health & life insurance coverage within overall limits of company policy to all employees to assist them in their hospitalization needs. It is
                            covered for Virescent & SPV employees, O&M contract employees covered under ESIC.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited by the value chain partners.
                            <ExpandMore
                                expand={expanded21}
                                onClick={handleExpandClick21}
                                aria-expanded={expanded21}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded21} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            VRET has a mechanism is in place, for TDS liability Traces sites is verified for demand and notices, Reconciliation of Form 26AS is done with books on Annual basis. In case
                            of contract workers, PF, ESI, PT, LWF challans are taken on monthly basis with the invoice, only on receipt of all the challans payment is released to the contractor.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Provide the number of employees/workers having suffered high consequence work-related injury/ill-health/fatalities (as reported in Q11 of Essential Indicators above), who have been are
                            rehabilitated and placed in suitable employment or whose family members have been placed in suitable employment:
                            <ExpandMore
                                expand={expanded22}
                                onClick={handleExpandClick22}
                                aria-expanded={expanded22}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded22} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Total no. of affected employees/ workers
                                        </strong></TableCell>
                                        <TableCell align="center" colSpan={2} width='200'><strong>No. of employees/workers that are rehabilitated and placed in
                                            suitable employment or whose family members have been
                                            placed in suitable employment
                                        </strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Current FY 23
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Previous FY 22
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Current FY 23
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Previous FY 22
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Employees
                                        </TableCell>
                                        <TableCell align='center' rowSpan={2} colSpan={4}>No injury/ill health nor fatality at our operational sites.
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Workers
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Does the entity provide transition assistance programs to facilitate continued employability and the management of career endings resulting from retirement or termination of employment? (Yes/ No)

                            <ExpandMore
                                expand={expanded23}
                                onClick={handleExpandClick23}
                                aria-expanded={expanded23}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded23} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Yes. VRET had one instance of retirement. VRET rehired the incumbent on consultancy basis to provide continuity.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Details on assessment of value chain partners:
                            <ExpandMore
                                expand={expanded24}
                                onClick={handleExpandClick24}
                                aria-expanded={expanded24}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded24} timeout="auto" unmountOnExit>
                    <Box mb={4}>
                        <Typography variant='body1'>
                            Yes such assessments are carried out for, O&M Contractors of our organization, as they are the most crucial Value chain partner as they are largest contributor in terms of business
                            size. Assessment of O&M Contractor is being carried out internally and from third party like KPMG as a overall company audit
                        </Typography>
                    </Box>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Topic</strong></TableCell>
                                        <TableCell align="center" ><strong>% of value chain partners (by value of business done with such partners) that were assessed
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Health and safety practices
                                        </TableCell>
                                        <TableCell align='center'>100%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Working Conditions
                                        </TableCell>
                                        <TableCell align='center'>100%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            6. Provide details of any corrective actions taken or underway to address significant risks / concerns arising from assessments of health and safety practices and working conditions of value chain partners.
                            <ExpandMore
                                expand={expanded25}
                                onClick={handleExpandClick25}
                                aria-expanded={expanded25}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded25} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            The contractors comply with HSSE / HR standards in accordance with VRET’s requirements as stipulated in the contractual agreement.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc3;