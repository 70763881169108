import React from 'react';
import sectiona from '../../images/Banners/sectiona.webp'

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
const StyledBox1 = styled(Box)`
  ${({ theme }) => `
  transition: ${theme.transitions.create(['transform'], {
    duration: "1s",
})};
  &:hover {
    transform: scale(1.03);
  }
  `}
`;

const Sectiona = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };
    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };
    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };
    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };
    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };
    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };
    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };
    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true);
        setExpanded2(true);
        setExpanded1(true);
        setExpanded3(true);
        setExpanded4(true);
        setExpanded5(true);
        setExpanded6(true);
        setExpanded7(true);
    };
    const closeAll = () => {
        setExpand(false);
        setExpanded1(false);
        setExpanded2(false);
        setExpanded3(false);
        setExpanded4(false);
        setExpanded5(false);
        setExpanded6(false);
        setExpanded7(false);
    };

    return (
        <Container>
            <StyledBox1 mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box p={5} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        General Disclosures
                    </Typography>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${sectiona})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </StyledBox1>
            <Box mt={4} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box my={4}>
                <Box mt={4} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                                I. Details of the listed entity
                                <ExpandMore
                                    expand={expanded1}
                                    onClick={handleExpandClick1}
                                    aria-expanded={expanded1}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={expanded1} timeout="auto" unmountOnExit>
                        <Box mb={5}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                        <TableRow sx={{ backgroundColor: "#67725d" }}>
                                            <TableCell align="center"><strong>S. No.</strong></TableCell>
                                            <TableCell align="center"><strong>Particulars held</strong></TableCell>
                                            <TableCell align="center" ><strong>Response</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>1</strong></TableCell>
                                            <TableCell><strong>Corporate Identity Number (CIN) of the Listed Entity</strong></TableCell>
                                            <TableCell>Not Applicable (As VRET is a registered  Trust.)</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>2</strong></TableCell>
                                            <TableCell><strong>Name of the Listed Entity</strong></TableCell>
                                            <TableCell>Virescent Renewable Energy Trust (VRET)</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>3</strong></TableCell>
                                            <TableCell><strong>Year of incorporation</strong></TableCell>
                                            <TableCell>2021</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>4</strong></TableCell>
                                            <TableCell><strong>Registered office address</strong></TableCell>
                                            <TableCell>2nd ﬂoor, Piramal Tower, Peninsula Corporate Park, Lower Parel, Mumbai - 400 013</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>5</strong></TableCell>
                                            <TableCell><strong>Corporate address</strong></TableCell>
                                            <TableCell>14th  Floor, A/1402, C- 38 & C-39, Parinee Crescenzo, G Block, Bandra Kurla Complex, Bandra (East) Mumbai - 400 051</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>6</strong></TableCell>
                                            <TableCell><strong>E-mail</strong></TableCell>
                                            <TableCell><a href='mailto:info@virescent.co.in'>info@virescent.co.in</a></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>7</strong></TableCell>
                                            <TableCell><strong>Telephone</strong></TableCell>
                                            <TableCell>91-9833849735</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>8</strong></TableCell>
                                            <TableCell><strong>Website</strong></TableCell>
                                            <TableCell><a href='https://virescent.co.in/' target="_blank" rel="noopener noreferrer">www.virescent.co.in</a></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>9</strong></TableCell>
                                            <TableCell><strong>Financial year for which reporting is being done</strong></TableCell>
                                            <TableCell>FY 2022-23
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>10</strong></TableCell>
                                            <TableCell><strong>Name of the Stock Exchange(s) where shares are listed</strong></TableCell>
                                            <TableCell>National Stock Exchange (NSE)</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>11</strong></TableCell>
                                            <TableCell><strong>Paid-up Capital</strong></TableCell>
                                            <TableCell>Unit capital - Rs. 2,01,000 lakhs</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>12</strong></TableCell>
                                            <TableCell><strong>Contact Person</strong></TableCell>
                                            <TableCell>Name: Mr. Pradeep Patil <br /> Telephone: 96501 099471 <br /> Email: <a href='mailto:pradeep.patil@virescent.co.in'>pradeep.patil@virescent.co.in</a></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" align='center'><strong>13</strong></TableCell>
                                            <TableCell><strong>Reporting Boundary (Standalone / Consolidated)</strong></TableCell>
                                            <TableCell>Standalone</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                                II. Products/services
                                <ExpandMore
                                    expand={expanded2}
                                    onClick={handleExpandClick2}
                                    aria-expanded={expanded2}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                        <Box mb={5}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ backgroundColor: "#67725d", "& td": { color: '#ffffff' } }} >
                                            <TableCell rowSpan={3} sx={{ backgroundColor: "#ffffff", color: '#000000 !important' }} align="center">14</TableCell>
                                            <TableCell rowSpan={3} sx={{ maxWidth: '140px' }} align="left"><strong>Details of business activities </strong></TableCell>
                                            <TableCell align="left"><strong>S.No.</strong></TableCell>
                                            <TableCell align="left"><strong>Description of Main Activity</strong></TableCell>
                                            <TableCell align="left"><strong>Description of Business Activity</strong></TableCell>
                                            <TableCell align="left"><strong>% of Turnover of the entity</strong></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left'>1</TableCell>
                                            <TableCell align='left'>Investment in Infrastructure</TableCell>
                                            <TableCell align='left'>Investment in Infrastructure</TableCell>
                                            <TableCell align='left'>100%</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left'>2</TableCell>
                                            <TableCell align='left'>Electricity</TableCell>
                                            <TableCell align='left'>Generation of Electricity</TableCell>
                                            <TableCell align='left'>100%</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#67725d", "& td": { color: '#ffffff' } }} >
                                            <TableCell rowSpan={3} sx={{ backgroundColor: "#ffffff", color: '#000000 !important' }} align="center">15</TableCell>
                                            <TableCell rowSpan={3} sx={{ maxWidth: '140px' }} align="left"><strong>Products/Services sold by the entity contributing 90% of total turnover</strong></TableCell>
                                            <TableCell align="left"><strong>S.No.</strong></TableCell>
                                            <TableCell align="left"><strong>Product/Service</strong></TableCell>
                                            <TableCell align="left"><strong>NIC Code</strong></TableCell>
                                            <TableCell align="left"><strong>% of Total Turnover contributed</strong></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left'>1</TableCell>
                                            <TableCell align='left'>Investment in Infrastructure</TableCell>
                                            <TableCell align='left'></TableCell>
                                            <TableCell align='left'>100%</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left'>2</TableCell>
                                            <TableCell align='left'>Electricity</TableCell>
                                            <TableCell align='left'>3510</TableCell>
                                            <TableCell align='left'>100%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                                III. Operations
                                <ExpandMore
                                    expand={expanded3}
                                    onClick={handleExpandClick3}
                                    aria-expanded={expanded3}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={expanded3} timeout="auto" unmountOnExit>
                        <Box mb={5}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ backgroundColor: "#67725d", "& td": { color: '#ffffff' } }} >
                                            <TableCell rowSpan={3} sx={{ backgroundColor: "#ffffff", color: '#000000 !important' }} align="center">16</TableCell>
                                            <TableCell rowSpan={3} sx={{ maxWidth: '140px' }} align="left"><strong>Number of locations where plants and/or operations/offices of the entity are situated: </strong></TableCell>
                                            <TableCell align="left"><strong>Location</strong></TableCell>
                                            <TableCell align="left"><strong>Number of plants</strong></TableCell>
                                            <TableCell align="left"><strong>Number of offices</strong></TableCell>
                                            <TableCell align="left"><strong>Total</strong></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left'>National</TableCell>
                                            <TableCell align='left'>20</TableCell>
                                            <TableCell align='left'>3</TableCell>
                                            <TableCell align='left'>23</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left'>International</TableCell>
                                            <TableCell align='left'>-</TableCell>
                                            <TableCell align='left'>-</TableCell>
                                            <TableCell align='left'>-</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#67725d", "& td": { color: '#ffffff' } }} >
                                            <TableCell rowSpan={5} sx={{ backgroundColor: "#ffffff", color: '#000000 !important' }} align="center">17</TableCell>
                                            <TableCell sx={{ maxWidth: '140px' }} align="left"><strong>Market served by the entity</strong></TableCell>
                                            <TableCell colSpan={2} align="left"><strong>Location</strong></TableCell>
                                            <TableCell colSpan={2} align="left"><strong>Numbers</strong></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell rowSpan={2} align='left' sx={{ backgroundColor: "#67725d", color: '#ffffff !important' }}>a.	No. of Locations</TableCell>
                                            <TableCell colSpan={2} align='left'>National (No. of States)</TableCell>
                                            <TableCell colSpan={2} align='left'>7</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell colSpan={2} align='left'>International (No. of Countries)</TableCell>
                                            <TableCell colSpan={2} align='left'>-</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left' sx={{ backgroundColor: "#67725d", color: '#ffffff !important' }}>b. What is the contribution of exports as a percentage of the total turnover of the entity?</TableCell>
                                            <TableCell colSpan={4} align='center'>-</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left' sx={{ backgroundColor: "#67725d", color: '#ffffff !important' }}>c. A brief on types of customers</TableCell>
                                            <TableCell colSpan={4} align='left'>Government DISCOM like NVVN, SECI, GUVNL, TANGENCO etc</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            IV. Employees
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                    <Collapse in={expanded4} timeout="auto" unmountOnExit>
                        <Box mb={4}>
                            <Typography variant='body1'>
                                <strong>18. Details as at the end of Financial Year:</strong>
                            </Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>S. No.</strong></TableCell>
                                        <TableCell align="center" rowSpan={2}><strong>Particulars</strong></TableCell>
                                        <TableCell align="center" rowSpan={2}><strong>Total (A)</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Male</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Female</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>No. (B)</strong></TableCell>
                                        <TableCell align="center"><strong>% (B/A)</strong></TableCell>
                                        <TableCell align="center"><strong>No. (C)</strong></TableCell>
                                        <TableCell align="center"><strong>% (C/A)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ backgroundColor: "#e0e0e0" }} component="th" scope="row" align='left' colSpan={7}><strong>a.	Employees (including differently abled)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='left' colSpan={7}><strong>Employees</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">1</TableCell>
                                        <TableCell>Permanent (D)</TableCell>
                                        <TableCell align='center'>98</TableCell>
                                        <TableCell align='center'>91</TableCell>
                                        <TableCell align='center'>93%</TableCell>
                                        <TableCell align='center'>7</TableCell>
                                        <TableCell align='center'>7%</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">2</TableCell>
                                        <TableCell>Other than Permanent (E) </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">3</TableCell>
                                        <TableCell>Total employees (D + E) </TableCell>
                                        <TableCell align='center'>98</TableCell>
                                        <TableCell align='center'>91</TableCell>
                                        <TableCell align='center'>93%</TableCell>
                                        <TableCell align='center'>7</TableCell>
                                        <TableCell align='center'>7%</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ backgroundColor: "#e0e0e0" }} component="th" scope="row" align='left' colSpan={7}><strong>b.	Workers (including differently abled)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='left' colSpan={7}><strong>Workers</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">4</TableCell>
                                        <TableCell>Permanent (F)</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">5</TableCell>
                                        <TableCell>Other than Permanent (G) </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">6</TableCell>
                                        <TableCell>Total workers (F + G) </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ backgroundColor: "#e0e0e0" }} component="th" scope="row" align='left' colSpan={7}><strong>c. Differently abled Employees	</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='left' colSpan={7}><strong>Employees</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">1</TableCell>
                                        <TableCell>Permanent</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>100%</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">2</TableCell>
                                        <TableCell>Other than Permanent</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">3</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>100%</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ backgroundColor: "#e0e0e0" }} component="th" scope="row" align='left' colSpan={7}><strong>c. Differently abled Workers	</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='left' colSpan={7}><strong>Workers</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">1</TableCell>
                                        <TableCell>Permanent</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">2</TableCell>
                                        <TableCell>Other than Permanent</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">3</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={5} mb={3}>
                            <Typography variant='body1'>
                                <strong>19. Participation/Inclusion/Representation of women</strong>
                            </Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>S. No.</strong></TableCell>
                                        <TableCell align="left" rowSpan={2}><strong>Category</strong></TableCell>
                                        <TableCell align="center" rowSpan={2}><strong>Total (A)</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>No. and % of Females</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>No. (B)</strong></TableCell>
                                        <TableCell align="center"><strong>% (B/A)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='left'>Board of Directors</TableCell>
                                        <TableCell align='center'>6</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>17%</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='center'>2</TableCell>
                                        <TableCell align='left'>Key Management Personnel</TableCell>
                                        <TableCell align='center'>2</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>50%</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align='center'>3</TableCell>
                                        <TableCell align='left'>Senior Management</TableCell>
                                        <TableCell align='center'>5</TableCell>
                                        <TableCell align='center'>1</TableCell>
                                        <TableCell align='center'>20%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={5} mb={3}>
                            <Typography variant='body1'>
                                <strong>20. Turnover rate for permanent employees and workers.</strong>
                            </Typography>
                        </Box>
                        <Box mb={5}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                        <TableRow sx={{ backgroundColor: "#67725d" }}>
                                            <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                            <TableCell align="center" colSpan={3}><strong>FY 2022-23<br></br>(Turnover rate in current FY)</strong></TableCell>
                                            <TableCell align="center" colSpan={3}><strong>FY 2021-22<br></br>(Turnover rate in previous FY)</strong></TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#67725d" }}>
                                            <TableCell align="center" ><strong>Male</strong></TableCell>
                                            <TableCell align="center" ><strong>Female</strong></TableCell>
                                            <TableCell align="center" ><strong>Total</strong></TableCell>
                                            <TableCell align="center" ><strong>Male</strong></TableCell>
                                            <TableCell align="center" ><strong>Female</strong></TableCell>
                                            <TableCell align="center" ><strong>Total</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">Permanent Employees</TableCell>
                                            <TableCell align='center'>29%</TableCell>
                                            <TableCell align='center'>15%</TableCell>
                                            <TableCell align='center'>28%</TableCell>
                                            <TableCell align='center'>8%</TableCell>
                                            <TableCell align='center'>-</TableCell>
                                            <TableCell align='center'>8%</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">Permanent Workers</TableCell>
                                            <TableCell align='center'>-</TableCell>
                                            <TableCell align='center'>-</TableCell>
                                            <TableCell align='center'>-</TableCell>
                                            <TableCell align='center'>13%</TableCell>
                                            <TableCell align='center'>38%</TableCell>
                                            <TableCell align='center'>50%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                                V. Holding, Subsidiary and Associate Companies (including joint ventures)
                                <ExpandMore
                                    expand={expanded5}
                                    onClick={handleExpandClick5}
                                    aria-expanded={expanded5}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={expanded5} timeout="auto" unmountOnExit>
                        <Box mb={3}>
                            <Typography variant='body1'>
                                <strong>21. Names of holding / subsidiary / associate companies / joint ventures.</strong>
                            </Typography>
                        </Box>
                        <Box mb={5}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                        <TableRow sx={{ backgroundColor: "#67725d" }}>
                                            <TableCell align="center"><strong>S. No.</strong></TableCell>
                                            <TableCell align="center"><strong>Name of the holding / subsidiary / associate companies / joint ventures (A)</strong></TableCell>
                                            <TableCell align="center" ><strong>Indicate whether holding/ Subsidiary/Associate/ Joint Venture</strong></TableCell>
                                            <TableCell align="center"><strong>% of shares held by listed entity</strong></TableCell>
                                            <TableCell align="center"><strong>Does the entity indicated at column A, participate in the Business Responsibility initiatives of the listed entity? (Yes/No)</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">1</TableCell>
                                            <TableCell>UMD</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">2</TableCell>
                                            <TableCell>TN Solar</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">3</TableCell>
                                            <TableCell>Terralight Kanji</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">4</TableCell>
                                            <TableCell>SEPEPL</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">5</TableCell>
                                            <TableCell>Terralight Tinwari</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">6</TableCell>
                                            <TableCell>Terralight Charanka</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">7</TableCell>
                                            <TableCell>PLG</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">8</TableCell>
                                            <TableCell>USUPL</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">9</TableCell>
                                            <TableCell>Rajapalayam assets</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">10</TableCell>
                                            <TableCell>Terralight Nangla</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">11</TableCell>
                                            <TableCell>Terralight Patlasi</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">12</TableCell>
                                            <TableCell>Terralight Gadna</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">13</TableCell>
                                            <TableCell>GSPPL</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">14</TableCell>
                                            <TableCell>GGEL</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>100%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">15</TableCell>
                                            <TableCell>Terralight Sitamau</TableCell>
                                            <TableCell align='center'>Subsidiary</TableCell>
                                            <TableCell align='center'>66%</TableCell>
                                            <TableCell align='center'>Yes</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                                VI. CSR Details
                                <ExpandMore
                                    expand={expanded6}
                                    onClick={handleExpandClick6}
                                    aria-expanded={expanded6}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={expanded6} timeout="auto" unmountOnExit>
                        <Box mb={4}>
                            <Typography variant='body1'>
                                <strong>22. (a) Whether CSR is applicable as per section 135 of Companies Act, 2013: (Yes/No).</strong>
                                <br></br>
                                Yes; it is applicable in the case of GGEPL and USUPL for FY23.
                            </Typography>
                        </Box>
                        <Box mb={5}>
                            <TableContainer sx={{ maxWidth: 450 }} component={Paper}>
                                <Table size="small">
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row"><strong>Turnover (in Rs.)</strong></TableCell>
                                            <TableCell>INR 9,760 Mn</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row"><strong>Net worth (in Rs.)</strong></TableCell>
                                            <TableCell>INR 5,266 Mn</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
                <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                    <Box mb={2}>
                        <Box>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                                VII. Transparency and Disclosures Compliances
                                <ExpandMore
                                    expand={expanded7}
                                    onClick={handleExpandClick7}
                                    aria-expanded={expanded7}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Typography>
                        </Box>
                    </Box>
                    <Collapse in={expanded7} timeout="auto" unmountOnExit>
                        <Box mb={4}>
                            <Typography variant='body1'>
                                <strong>23. Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on Responsible Business Conduct:</strong>
                            </Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center" rowSpan={2}><strong>Stakeholder group from whom complaint is received</strong></TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center" rowSpan={2}><strong>Grievance Redressal Mechanism in Place (Yes/No) If Yes, then provide web-link for grievance redress policy</strong></TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center" colSpan={3}><strong>FY 2022-23 <br />Current Financial Year</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center" ><strong>Number of complaints filed during the year</strong></TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center" ><strong>Number of complaints pending resolution at close of the year</strong></TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center" ><strong>Remarks</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Communities</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>1</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>NA</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Investors (other than shareholders)</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>NA</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Shareholders</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>NA</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Employees and workers</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>NA</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Customers</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>NA</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Value Chain Partners</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>NA</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} component="th" scope="row">Other (please specify)</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>Yes</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>3</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>-</TableCell>
                                        <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='center'>The complaints were received from Contractors</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box my={4}>
                            <Typography variant='body1'>
                                <strong>24. Overview of the entity's material responsible business conduct issues. Please indicate material responsible business conduct and sustainability issues pertaining to environmental and social matters
                                    that present a risk or an opportunity to your business, rationale for identifying the same, approach to adapt or mitigate the risk along-with its financial implications, as per the following format.
                                </strong>
                            </Typography>
                        </Box>
                        <Box mb={5}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                        <TableRow sx={{ backgroundColor: "#67725d" }}>
                                            <TableCell align="center"><strong>Material Issue Identified (High priority material issues are listed below)</strong></TableCell>
                                            <TableCell align="center"><strong>Indicate whether risk or opportunity</strong></TableCell>
                                            <TableCell align="center"><strong>Rationale for identifying the risk/opportunity</strong></TableCell>
                                            <TableCell align="center"><strong>In case of risk, approach to adapt or mitigate</strong></TableCell>
                                            <TableCell align="center"><strong>Financial implications of the risk or opportunity (Indicate positive or negative implications)</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell colSpan={5}>
                                                Materiality assessment has been done in June-2022. Refer Materiality Assessment (page 12) in FY 22 ESG report. <br />
                                                <a href="https://virescent.co.in/wp-content/uploads/2022/08/V2_ESG-Report_09_08_22-hi-res.pdf" target="_blank" rel="noopener noreferrer">
                                                    https://virescent.co.in/wp-content/uploads/2022/08/V2_ESG-Report_09_08_22-hi-res.pdf
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
        </Container >
    )
}

export default Sectiona;