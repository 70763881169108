import * as React from 'react';

//MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { usePopupState, bindHover, bindMenu, } from 'material-ui-popup-state/hooks'

//Images
import logo from '../images/virescentLogo.webp'



export default function Navbar() {

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'navMenu',
    })

    const menuItem = {
        color: "#666",
        paddingBottom: 0,
        paddingTop: 0,
        "&:hover": {
            fontWeight: 'bold',
        },
    }

    const sxLink = {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: '100%'
    }

    const [open, setState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };


    return (
        <Box>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <Box mt={1} sx={{ flexGrow: 1 }}>
                        <a href='/'>
                            <img src={logo} alt="" height="60px" style={{ maxWidth: 200 }} />
                        </a>
                    </Box>

                    {/* On Large Screen */}
                    <Box sx={{
                        display: {
                            xs: 'none',
                            lg: 'flex',
                        },
                        alignItems: 'center'
                    }}>
                        <Box display="flex" alignItems="center" sx={{ marginRight: "45px" }} {...bindHover(popupState)}>
                            <Link underline="none" href='/brsr' className={window.location.pathname === '/brsr' ? 'unActive' : 'un'} color='inherit'>
                                Business Responsibility and Sustainability Report
                            </Link>
                            <ArrowDropDownIcon />
                            <span style={{ visibility: 'hidden', paddingBottom: '20px' }}>.</span>
                        </Box>
                        <HoverMenu
                            {...bindMenu(popupState)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectiona' underline="none">General Disclosures</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionb' underline="none">Management &amp; Process Disclosures</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc1' underline="none">Principle 1: Integrity and Ethics</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc2' underline="none">Principle 2: Sustainable Product</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc3' underline="none">Principle 3: Employees Wellbeing</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc4' underline="none">Principle 4: Stakeholders Interest</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc5' underline="none">Principle 5: Human Rights</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc6' underline="none">Principle 6: Environment</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc7' underline="none">Principle 7: Regulatory Policy</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc8' underline="none">Principle 8: Inclusive Growth</Link>
                            </MenuItem>
                            <Divider style={{ margin: 0 }} />
                            <MenuItem sx={menuItem}>
                                <Link sx={sxLink} href='/sectionc9' underline="none">Principle 9: Consumer and IT</Link>
                            </MenuItem>
                        </HoverMenu>
                        <Link underline="none" href='/sasb-metrics' className={window.location.pathname === '/sasb-metrics' ? 'unActive' : 'un'} color='inherit'>
                            SASB Sector Metrics
                        </Link>
                    </Box>

                    {/* On Small Screen */}
                    <Box sx={{
                        display: {
                            xs: 'flex',
                            lg: 'none'
                        }
                    }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                            sx={{
                                mr: 2,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Drawer
                            anchor="right"
                            open={open}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            PaperProps={{
                                sx: {
                                    backgroundColor: "#67725d"
                                }
                            }}
                        >
                            <Box>
                                <IconButton sx={{ my: 2 }} onClick={toggleDrawer(false)}>
                                    <CloseIcon sx={{ color: "#000000" }} />
                                </IconButton>

                                <Divider sx={{ mb: 2, bgcolor: "white" }} />
                                <ListItemButton href='/sasb-metrics'
                                    sx={{
                                        '&:hover': {
                                            color: '#000000',
                                            fontWeight: 'bold'
                                        },
                                        color: window.location.pathname === '/sasb-metrics' ? '#000000' : 'white',
                                        fontWeight: window.location.pathname === '/sasb-metrics' ? 'bold' : 'normal'
                                    }}
                                >
                                    <ListItemText primary="SASB Metrics" />
                                </ListItemButton>
                                <ListItemButton href='/brsr'
                                    sx={{
                                        '&:hover': {
                                            color: '#000000',
                                            fontWeight: 'bold'
                                        },
                                        color: window.location.pathname === '/brsr' ? '#000000' : 'white',
                                        fontWeight: window.location.pathname === '/brsr' ? 'bold' : 'normal'
                                    }}
                                >
                                    <ListItemText primary="BRSR" />
                                </ListItemButton>
                                <Box sx={{ color: "white", paddingLeft: '28px' }}>
                                    <ListItemButton href='/sectiona'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectiona' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectiona' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="General Disclosures" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionb'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionb' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionb' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Management and Process Disclosures" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc1'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc1' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc1' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 1" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc2'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc2' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc2' ? 'bold' : 'normal'

                                        }}
                                    >
                                        <ListItemText primary="Principle 2" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc3'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc3' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc3' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 3" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc4'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc4' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc4' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 4" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc5'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc5' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc5' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 5" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc6'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc6' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc6' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 6" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc7'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc7' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc7' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 7" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc8'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc8' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc8' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 8" />
                                    </ListItemButton>
                                    <ListItemButton href='/sectionc9'
                                        sx={{
                                            '&:hover': {
                                                color: '#000000',
                                                fontWeight: 'bold'
                                            },
                                            color: window.location.pathname === '/sectionc9' ? '#000000' : 'white',
                                            fontWeight: window.location.pathname === '/sectionc9' ? 'bold' : 'normal'
                                        }}
                                    >
                                        <ListItemText primary="Principle 9" />
                                    </ListItemButton>
                                </Box>
                            </Box>
                        </Drawer>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box >
    );
}