import * as React from 'react';
import { createElement } from 'react';
import { Card, Box, Typography } from '@mui/material';
import CountUp from 'react-countup';
import Avatar from '@mui/material/Avatar';


const Flashcard = ({ icon, title, value, bgcolor, prefix="", suffix="", iconColor="#ffffff" }) => (
    <Card sx={{
        padding: '12px',
        minHeight: 52,
        display: 'flex',
        borderRadius: '10px',
        alignItems: 'center',
        backgroundColor: bgcolor
    }}>
        <Box mr={2}>
            <Avatar sx={{ width: 56, height: 56, padding: '8px', backgroundColor: '#5a6054' }}>
                {createElement(icon, { sx: { fontSize: 36, color: iconColor  } })}
            </Avatar>
        </Box>
        <Box>
            <Typography gutterBottom variant="body2">
                {title}
            </Typography>
            <Typography variant="h4" component='span'>
                {prefix}
                <CountUp end={value} separator="," duration={2} />
                {' '}
            </Typography>
            <Typography variant="body1" component='span'>
                {suffix}
            </Typography>
        </Box>
    </Card>
);

export default Flashcard;