import React from 'react';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im1 from '../../images/SDG/1.jpg';
import im2 from '../../images/SDG/2.jpg';
import im3 from '../../images/SDG/3.jpg';
import im4 from '../../images/SDG/4.jpg';
import im5 from '../../images/SDG/5.jpg';
import im8 from '../../images/SDG/8.jpg';
import im9 from '../../images/SDG/9.jpg';
import im10 from '../../images/SDG/10.jpg';
import im11 from '../../images/SDG/11.jpg';
import im13 from '../../images/SDG/13.jpg';
import im14 from '../../images/SDG/14.jpg';
import im15 from '../../images/SDG/15.jpg';
import im16 from '../../images/SDG/16.jpg';
import im17 from '../../images/SDG/17.jpg';
import section8 from '../../images/Banners/section8.webp'



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(1.8);
  }
  `}
`;



const Sectionc8 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expanded10, setExpanded10] = React.useState(false);
    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    };

    const [expanded11, setExpanded11] = React.useState(false);
    const handleExpandClick11 = () => {
        setExpanded11(!expanded11);
    };

    const [expanded12, setExpanded12] = React.useState(false);
    const handleExpandClick12 = () => {
        setExpanded12(!expanded12);
    };


    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true); setExpanded1(true); setExpanded2(true); setExpanded3(true); setExpanded4(true); setExpanded5(true); setExpanded6(true); setExpanded7(true); setExpanded8(true); setExpanded9(true); setExpanded10(true); setExpanded11(true); setExpanded12(true);
    };
    const closeAll = () => {
        setExpand(false); setExpanded1(false); setExpanded2(false); setExpanded3(false); setExpanded4(false); setExpanded5(false); setExpanded6(false); setExpanded7(false); setExpanded8(false); setExpanded9(false); setExpanded10(false); setExpanded11(false); setExpanded12(false);
    };

    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 8
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Inclusive Growth
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should promote inclusive growth and equitable development
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im1} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im2} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im3} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im4} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im5} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im8} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im9} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im10} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im11} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im13} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im14} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im15} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im16} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im17} height="40" alt="svg" /> </StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section8})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Details of Social Impact Assessment(s) of projects undertaken by the entity based on applicable laws, in the current financial year.
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            No such requirements specified for Solar plant operations. During M&A Environmental and Social Due diligence is being carried out before acquiring
                            the plant.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Provide information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being undertaken by your entity, in the following format:
                            <ExpandMore
                                expand={expanded2}
                                onClick={handleExpandClick2}
                                aria-expanded={expanded2}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not Applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Describe the mechanisms to receive and redress grievances of the community.
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Anonymous complaints and grievances can be filed at <a href='mailto:general.counsel@virescent.co.in'>general.counsel@virescent.co.in</a> and on <a href='grievance@virescent.co.in' target="_blank" rel="noopener noreferrer">grievance@virescent.co.in</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Percentage of input material (inputs to total inputs by value) sourced from suppliers:
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"><strong>FY 2022-23<br></br>
                                            (Current Financial Year)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>FY 2021-22<br></br>
                                            (Previous Financial Year)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Directly sourced from MSMEs/ small producers
                                        </TableCell>
                                        <TableCell align="center">50.0%</TableCell>
                                        <TableCell align="center">50.0%</TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Sourced directly from within the district and neighbouring districts
                                        </TableCell>
                                        <TableCell align="center">-</TableCell>
                                        <TableCell align="center">-</TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact Assessments (Reference: Question 1 of Essential Indicators above):
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            No such requirements specified for Solar plant operations. During M&A Environmental and Social Due diligence is being carried out before acquiring the plant.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Provide the following information on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies:
                            <ExpandMore
                                expand={expanded6}
                                onClick={handleExpandClick6}
                                aria-expanded={expanded6}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Virescent Infrastructure appointed Samhita Social Ventures, to provide consultancy services with respect to executing Virescent's CSR and suggesting programmatic ideas, project implementation, and end-to-end management. We are committed to the cause and have focused on thematic areas of education, health and well being & community development for FY22-23. Total Project budget was Rs. 58,98,356 /- and actual amount utilized was of Rs. 59,05,589/-
                            CSR report for  FY 2021-22 can be accessed at <a href='https://virescent.co.in/wp-content/uploads/2022/08/V1_Report-on-CSR-Project_08_08_22.pdf' target="_blank" rel="noopener noreferrer">V1_Feild Report_08_08_22 (virescent.co.in)</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3.a. Do you have a preferential procurement policy where you give preference to purchase from suppliers comprising marginalized /vulnerable groups? (Yes/No)
                            <ExpandMore
                                expand={expanded7}
                                onClick={handleExpandClick7}
                                aria-expanded={expanded7}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            No
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3.b. From which marginalized /vulnerable groups do you procure?
                            <ExpandMore
                                expand={expanded8}
                                onClick={handleExpandClick8}
                                aria-expanded={expanded8}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            -
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3.c. What percentage of total procurement (by value) does it constitute?
                            <ExpandMore
                                expand={expanded9}
                                onClick={handleExpandClick9}
                                aria-expanded={expanded9}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            -
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge
                            <ExpandMore
                                expand={expanded12}
                                onClick={handleExpandClick12}
                                aria-expanded={expanded12}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded12} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            There are no Intellectual Property.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Details of corrective actions taken or underway, based on any adverse order in intellectual property related disputes wherein usage of traditional knowledge is involved.
                            <ExpandMore
                                expand={expanded10}
                                onClick={handleExpandClick10}
                                aria-expanded={expanded10}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded10} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            There are no Intellectual Property.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            6. Details of beneficiaries of CSR Projects
                            <ExpandMore
                                expand={expanded11}
                                onClick={handleExpandClick11}
                                aria-expanded={expanded11}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded11} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>S.
                                            No</strong></TableCell>
                                        <TableCell align="center"><strong>CSR Project</strong></TableCell>
                                        <TableCell align="center"><strong>No. of persons benefitted
                                            from CSR Projects</strong></TableCell>
                                        <TableCell align="center"><strong>% of beneficiaries from
                                            vulnerable and marginalized
                                            groups
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                        </TableCell>
                                        <TableCell colSpan={3}>VRET undertakes CSR activity at all locations with aim to promote health and well being, education and community development. The activities include provision of Solar streetlights, water purification system, desktop etc. for local schools and community. The beneficiaries are local communities in the plant neighborhood.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc8;