import React from 'react';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im2 from '../../images/SDG/2.jpg';
import im4 from '../../images/SDG/4.jpg';
import im12 from '../../images/SDG/12.jpg';
import im14 from '../../images/SDG/14.jpg';
import im15 from '../../images/SDG/15.jpg';
import im16 from '../../images/SDG/16.jpg';
import section9 from '../../images/Banners/section9.webp'


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(1.8);
  }
  `}
`;


const Sectionc9 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };


    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expanded10, setExpanded10] = React.useState(false);
    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    };

    const [expanded11, setExpanded11] = React.useState(false);
    const handleExpandClick11 = () => {
        setExpanded11(!expanded11);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true); setExpanded1(true); setExpanded2(true); setExpanded3(true); setExpanded4(true); setExpanded5(true); setExpanded6(true); setExpanded7(true); setExpanded8(true); setExpanded9(true); setExpanded10(true); setExpanded11(true);
    };
    const closeAll = () => {
        setExpand(false); setExpanded1(false); setExpanded2(false); setExpanded3(false); setExpanded4(false); setExpanded5(false); setExpanded6(false); setExpanded7(false); setExpanded8(false); setExpanded9(false); setExpanded10(false); setExpanded11(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 9
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Consumer and IT
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should engage with and provide value to their consumers in a responsible manner
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im2} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im4} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im12} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im14} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im15} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im16} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section9})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Describe the mechanisms in place to receive and respond to consumer complaints and feedback.
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Grievance officer details and Hotline number is disclosed on the Website. Customer can get in touch through them. <a href='https://virescent.co.in/' target="_blank" rel="noopener noreferrer">www.virescent.co.in</a>
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Turnover of products and/ services as a percentage of turnover from all products/service that carry information about:

                            <ExpandMore
                                expand={expanded2}
                                onClick={handleExpandClick2}
                                aria-expanded={expanded2}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Category</strong></TableCell>
                                        <TableCell align="center"><strong>As a percentage to total turnover</strong></TableCell>
                                        <TableCell align="center"><strong>Remarks</strong></TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Environmental and social parameters relevant to the product
                                        </TableCell>
                                        <TableCell rowSpan={3} align='center'> 100% </TableCell>
                                        <TableCell rowSpan={3}> Business of renewable energy
                                        </TableCell>


                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Safe and responsible usage
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Recycling and/or safe disposal
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Number of consumer complaints in respect of the following:
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper} m>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2022-23 <br />(Current Financial  Year)
                                        </strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Received during the year
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Pending resolution at
                                            end of year
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Remarks</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Data privacy
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>- </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Advertising
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>- </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Cyber-security
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>- </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Delivery of essential services
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>- </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Unfair Trade Practices
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>- </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Other
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>- </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Details of instances of product recalls on account of safety issues:
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"><strong>Number</strong></TableCell>
                                        <TableCell align="center"><strong>Reasons for recall</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Voluntary recalls
                                        </TableCell>
                                        <TableCell align='center' rowSpan={2} colSpan={2}>Not Applicable </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Forced recalls
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If available, provide a web-link of the policy.
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            VRET has IT policy hosted on intranet.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            6. Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances
                            of product recalls; penalty / action taken by regulatory authorities on safety of products / services.
                            <ExpandMore
                                expand={expanded6}
                                onClick={handleExpandClick6}
                                aria-expanded={expanded6}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not Applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Channels / platforms where information on products and services of the entity can be accessed (provide web link, if available):
                            <ExpandMore
                                expand={expanded7}
                                onClick={handleExpandClick7}
                                aria-expanded={expanded7}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Steps taken to inform and educate consumers about safe and responsible usage of products and/or services.
                            <ExpandMore
                                expand={expanded8}
                                onClick={handleExpandClick8}
                                aria-expanded={expanded8}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.
                            <ExpandMore
                                expand={expanded9}
                                onClick={handleExpandClick9}
                                aria-expanded={expanded9}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Standard emails can be issued in case of disruption/ discontinuation of essential services.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Does the entity display product information on the product over and above what is mandated as per local laws? (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any
                            survey with regard to consumer satisfaction relating to the major products / services of the entity, significant locations of operation of the entity or the entity as a whole? (Yes/No)
                            <ExpandMore
                                expand={expanded10}
                                onClick={handleExpandClick10}
                                aria-expanded={expanded10}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded10} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            No
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Provide the following information relating to data breaches
                            <ExpandMore
                                expand={expanded11}
                                onClick={handleExpandClick11}
                                aria-expanded={expanded11}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded11} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            No breaches are reported. Incident tracker is maintained.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc9;