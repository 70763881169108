import React from 'react';
import "../index.css";

//MUI
import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';

//Images and media
import logo from '../images/virescentLogo.webp'
import bannerVid from '../images/Banners/HomePage.mp4'



const Home = () => {
    return (
        <Box>
            <div className="video-container" style={{ height: '95vh' }}>
                <video autoPlay muted loop>
                    <source src={bannerVid} type="video/mp4" />
                </video>
                <Box pt={2} className="caption" sx={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <img src={logo} alt="" height="100px" />
                    <Typography mt={6} variant='h4' sx={{ color: "#ffffff", fontSize: '27px', fontWeight: 'bold' }}>
                        ESG Performance
                    </Typography>
                    <Box>
                        <Box mt={10} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: {
                                xs: 'column',
                                sm: 'row',
                            },
                        }}>
                            <Card raised component="a" href='/brsr' sx={{
                                width: 280,
                                opacity: 0.85,
                                "&:hover": {
                                    opacity: 1
                                },
                                textDecoration: 'none',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: '#67715c'
                            }}>
                                <Box p={2}>
                                    <Typography variant="h6" component="div" align="center" sx={{ fontWeight: 'bold' }}>
                                        Business Responsibility and Sustainability Report (BRSR)
                                    </Typography>
                                </Box>
                            </Card>
                            <Box m={3}></Box>
                            <Card raised component="a" href='/sasb-metrics' sx={{
                                width: 280,
                                opacity: 0.85,
                                "&:hover": {
                                    opacity: 1
                                },
                                textDecoration: 'none',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: '#67715c'
                            }}>
                                <Box p={2}>
                                    <Typography variant="h6" component="div" align="center" sx={{ fontWeight: 'bold' }}>
                                        Sustainability Accounting Standards Board (SASB) - Sector Metrics
                                    </Typography>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    )
}

export default Home;
