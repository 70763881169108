import React from 'react';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im2 from '../../images/SDG/2.jpg';
import im7 from '../../images/SDG/7.jpg';
import im9 from '../../images/SDG/9.jpg';
import im10 from '../../images/SDG/10.jpg';
import im11 from '../../images/SDG/11.jpg';
import im13 from '../../images/SDG/13.jpg';
import im14 from '../../images/SDG/14.jpg';
import im15 from '../../images/SDG/15.jpg';
import im16 from '../../images/SDG/16.jpg';
import im17 from '../../images/SDG/17.jpg';
import section7 from '../../images/Banners/section7.webp'

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(1.8);
  }
  `}
`;



const Sectionc7 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true);
        setExpanded1(true);
        setExpanded2(true);
        setExpanded3(true);
        setExpanded4(true);
    };
    const closeAll = () => {
        setExpand(false);
        setExpanded1(false);
        setExpanded2(false);
        setExpanded3(false);
        setExpanded4(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 7
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Regulatory Policy
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses, when engaging in influencing public and regulatory policy,
                            should do so in a manner that is responsible and transparent
                        </Typography>
                    </Box>
                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im2} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im7} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im9} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im10} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im11} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im13} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im14} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im15} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im16} height="40" alt="svg" /> </StyledBox>
                        <StyledBox><img src={im17} height="40" alt="svg" /> </StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section7})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box mt={8} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={3}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. a. Number of affiliations with trade and industry chambers/ associations.
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            We are not affiliated with any trade and industry chambers.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. b. List the top 10 trade and industry chambers/ associations (determined based on the total members of such body) the entity is a member of/ affiliated to.
                            <ExpandMore
                                expand={expanded2}
                                onClick={handleExpandClick2}
                                aria-expanded={expanded2}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>S.
                                            No</strong></TableCell>
                                        <TableCell align="center"><strong>Name of the trade and industry chambers/associations</strong></TableCell>
                                        <TableCell align="center"><strong>Reach of trade and industry chambers/associations (State/National)</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Provide details of corrective action taken or underway on any issues related to conduct by the entity, based on adverse orders from regulatory authorities
                            <ExpandMore
                                expand={expanded3}
                                onClick={handleExpandClick3}
                                aria-expanded={expanded3}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>S.
                                            No</strong></TableCell>
                                        <TableCell align="center"><strong>Name of authority</strong></TableCell>
                                        <TableCell align="center"><strong>Brief of the case</strong></TableCell>
                                        <TableCell align="center"><strong>Corrective action taken</strong></TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>

                                        </TableCell>
                                        <TableCell align='center' colSpan={3}>Not Applicable</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        1. Details of public policy positions advocated by the entity
                        <ExpandMore
                            expand={expanded4}
                            onClick={handleExpandClick4}
                            aria-expanded={expanded4}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>S.
                                            No</strong></TableCell>
                                        <TableCell align="center"><strong>Public policy advocated </strong></TableCell>
                                        <TableCell align="center"><strong>Method resorted for such advocacy
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Whether information
                                            available in public
                                            domain? (Yes/No)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Frequency of Review by
                                            Board (Annually/ Half
                                            yearly/ Quarterly / Others
                                            – please specify)
                                        </strong></TableCell>
                                        <TableCell align="center"><strong>Web Link, if
                                            available
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>

                                        </TableCell>
                                        <TableCell colSpan={5}> Refer our reports: <a href='https://virescent.co.in/empowering-sustainable-future/#our-progress' target="_blank" rel="noopener noreferrer">https://virescent.co.in/empowering-sustainable-future/#our-progress</a>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc7;