import React from 'react';
import "../index.css";
import Sections from "../components/sections";


//MUI
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

//Images and Media
import logo from '../images/virescentLogo.webp'
import bannerVid from '../images/Banners/BRSRPage.mp4'
import ceo from '../images/Leaders/ceo.webp'
import coo from '../images/Leaders/coo.webp'



const BRSR = () => {
    return (
        <Box>
            <div className="video-container">
                <video autoPlay muted loop>
                    <source src={bannerVid} type="video/mp4" />
                </video>
                <Box className="caption" pt={12}>
                    <img src={logo} alt="" height="100px" />
                    <Typography variant='h4' sx={{ color: "#ffffff", fontSize: '27px', fontWeight: 'bold', textAlign: 'left', marginLeft: { xs: '28%', sm: '38%', md: '44%' } }}>
                        <span style={{ fontWeight: 800, color: '#ffd444' }}>B</span>usiness<br /> <span style={{ fontWeight: 800, color: '#ffd444' }}>R</span>esponsibility and<br /> <span style={{ fontWeight: 800, color: '#ffd444' }}>S</span>ustainability<br /> <span style={{ fontWeight: 800, color: '#ffd444' }}>R</span>eport
                    </Typography>
                </Box>
            </div>
            <Box px={5} pt={8} pb={4} sx={{ backgroundColor: "#f7f5f5" }}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box ml={{ lg: 3 }}>
                            <img src={ceo} height="350" style={{ border: "14px solid #fff" }} alt="ceo_image" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8}>
                        <Box>
                            <Box mb={2}>
                                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: 'bold' }}>
                                    Message from the CEO & Executive Director
                                </Typography>
                            </Box>
                            <Box mb={2} sx={{ maxHeight: 310, overflowY: "scroll" }} className="scroll">
                                <Typography variant='body1' sx={{ textAlign: "justify", marginRight: '20px' }}>
                                    Dear Stakeholders,
                                    <br /><br />
                                    It gives me immense pleasure to share with you Virescent Renewable Energy Trust's (VRET) BRSR report for FY 2022-23. This report represents our commitment to building a responsible company having a meaningful impact on the environment, our people, and the communities around us. The report is a culmination of important ESG disclosures required by the regulators and global standards and showcases how VRET is rigorously monitoring a range of sustainability information throughout the year.
                                    <br /><br />
                                    We recognise the importance of setting long-term goals, with the three pillars of Environmental (E), Social (S), and Governance (G) to keep ourselves accountable for our business actions and their impacts. Our goal is to ensure the provision of clean energy to our customers and reduce environmental impacts. We want to power operations completely through renewable energy in the future. We are continuously working towards increasing our renewable energy capacity to extend our sustainable energy supply reach.
                                    <br /><br />
                                    Human capital is our priority, with particular emphasis on health and safety, employee skill development, and provision of safe working conditions at our sites and offices. We are dedicated to fostering a safe, diverse and inclusive work environment for our employees and are working to ensure the same across our value chain partners. We are sure that our approach to sustainability will enable us to deliver on our pledge to build a brighter tomorrow by creating long-term value for our stakeholders.
                                    <br /><br />
                                    I want to take this opportunity to thank our stakeholders for your unwavering belief in our growth journey. Your continuous support and faith in us, have encouraged us to continue on our path to sustainable growth. We can attain the best results if we work together.
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#67725d" }}>
                                        Mr. Sanjay Grewal
                                    </Typography>
                                    <Typography>
                                        CEO & Executive Director
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box px={5} pt={5} pb={8} sx={{ backgroundColor: "#f7f5f5" }}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box ml={{ lg: 3 }}>
                            <img src={coo} height="350" style={{ border: "14px solid #fff" }} alt="coo_image" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8}>
                        <Box>
                            <Box mb={2}>
                                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: 'bold' }}>
                                    Message from the Chief Operating Officer
                                </Typography>
                            </Box>
                            <Box mb={2} sx={{ maxHeight: 310, overflowY: "scroll" }} className="scroll">
                                <Typography variant='body1' sx={{ textAlign: "justify", marginRight: '20px' }}>
                                    Dear Stakeholders,
                                    <br /><br />
                                    As we mark our financial performance and profits responsibly towards our stakeholders; we also measure our sustainability performance, including greenhouse gas reductions, reducing water consumption and enhancing the lives of communities in which we operate. These efforts demonstrate the integration of profit with purpose, to produce long-term value for all of our stakeholders, including investors, employees, and society.
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Typography variant="h6" sx={{ color: "#67725d" }}>
                                        Mr. Atul Raaizada
                                    </Typography>
                                    <Typography>
                                        Chief Operating Officer
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box m={{ xs: 4, sm: 8 }}>
                <Box my={4}>
                    <Typography variant='h4' gutterBottom sx={{ color: "#67725d", fontWeight: 'bold' }}>
                        BRSR Report
                    </Typography>
                </Box>
                <Box>
                    <Sections />
                </Box>
            </Box>
        </Box>
    )
}

export default BRSR;
