import Flashcard from "./ReusableComponents/flashcard";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// MUI Icons
import Co2Icon from '@mui/icons-material/Co2';
import Face3Icon from '@mui/icons-material/Face3';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import Groups2Icon from '@mui/icons-material/Groups2';

const SASB = () => {
    return (
        <Container>
            <Box mt={8}>
                <Typography variant='h5' gutterBottom sx={{ color: "#67725d", fontWeight: 'bold' }}>
                    Sustainability Accounting Standards Board - Sector Metrics
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ my: 4, pb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Flashcard
                            iconColor="#f44336"
                            icon={Co2Icon}
                            title="Total GHG Emissions"
                            value={6532}
                            suffix="MTCO₂e"
                            bgcolor="#ffebee"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Flashcard
                            iconColor="#4fc3f7"
                            icon={WaterDropIcon}
                            title="Total Water Withdrawn"
                            value={398000}
                            suffix="m³"
                            bgcolor="#e3f2fd"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Flashcard
                            iconColor="#ffc107"
                            icon={Groups2Icon}
                            title="Number of Employees"
                            value={98}
                            bgcolor="#fff8e1"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Flashcard
                            iconColor="#b39ddb"
                            icon={Face3Icon}
                            title="Women Directors"
                            value={17}
                            suffix="%"
                            bgcolor="#ede7f6"
                        />
                    </Grid>
                </Grid>
                <Box mt={10}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="Water withdrawal">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center" colSpan={6} sx={{ padding: '16px', fontSize: '16px', border: "1.5px solid #C3C4BD" }}><strong>SASB PERFORMANCE</strong></TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: "#2e2e28" }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align='left'><strong>Topic</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left"><strong>Sector Standard</strong></TableCell>
                                    <TableCell sx={{ minWidth: '140px', border: "1.5px solid #C3C4BD" }} align="left"><strong>Accounting Metrics</strong></TableCell>
                                    <TableCell sx={{ minWidth: '140px', border: "1.5px solid #C3C4BD" }} align="left"><strong>Key Performance Indicators</strong></TableCell>
                                    <TableCell sx={{ minWidth: '200px', border: "1.5px solid #C3C4BD" }} align="center"><strong>Virescent <br /> (April'22 - March'23)</strong></TableCell>
                                    <TableCell sx={{ minWidth: '125px', border: "1.5px solid #C3C4BD" }} align="left"><strong>SASB Metric Code</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={2}><strong>1. Greenhouse emissions and energy resource planning</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={2}>Electric Utilities (Infrastructure sector)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Scope 1, 2 and 3 emission numbers</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Metric tons (t) CO₂e, Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Total: 6,532 <br /> Scope 2: 6,307 <br /> Scope 3: 225 (1 out of 15 categories)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-110a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Discussion of long-term and short-term strategy and targets</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Discussion and Analysis</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="justify">VRET track the greenhouse gas emissions from its processes and now aimed to eliminate these emissions through consistent reduction. <br /><br /> VRET is also committed to reducing Scope 3 emissions, that are released into the environment indirectly as a result of operations but come from sources not owned or managed by VRET.</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-110a.3</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }}><strong>2. Air Quality</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }}>Electric Utilities (Infrastructure sector)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Emissions of air pollutants</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Tons (t), Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Not applicable being a clean energy company</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-120a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={4}><strong>3. Water Management</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={4}>Electric Utilities & Solar Technology</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Total water withdrawn from sources</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Thousand cubic meters (m³)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">398</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-140a.1 <br /> RR-ST-140a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Total water withdrawn from high stress areas</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">7 plants located in water stress areas</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-140a.1 <br /> RR-ST-140a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Incidents of non-compliance</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number, days</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center">-</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-140a.2</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Water management risks and strategies to mitigate</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Discussion and Analysis</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="justify">Water as a precious resource which is required for cleaning of the solar panels. VRET is on the path to achieve water stewardship across its activities. <br /><br /> Reducing water usage is a key factor in ensuring a prosperous future. VRET has rainwater recharge pits to replenish the groundwater at all sites.</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">IF-EU-140a.3 <br /> RR-ST-140a.2</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={2}><strong>4. Hazardous waste</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={2}>Solar Technology (Renewable resources sector)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Amount of hazardous waste generated, percentage recycled </TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Metric tons (t), Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">0.022 *</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">RR-ST-150a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Quantity of reportable spills and recovery</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number, Kilograms (kg)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="center">-</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">RR-ST-150a.2</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={2}><strong>5. Human Capital Development</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={2}>Asset management (Financials sector) & Internet Media (Technology sector)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number of Employees</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">98</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left"> FN-AC-330a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Voluntary Turnover (Resignations, Retirement)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number, percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">28% (corp.)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">TC-IM-330a.2</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={5}><strong>6. Diversity And Inclusion</strong></TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} rowSpan={5}>Asset management & Custody Activities (Financials sector)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Board of Directors breakdown:</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number, percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">6 directors: 3 Independent, 2 Non-executive and 1 executive director</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">FN-AC-330a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Gender diversity</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number, percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">17% women independent director</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left"></TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Male Employees</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">93%</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left"> FN-AC-330a.1</TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Female Employees</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">7%</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">FN-AC-330a.1</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Employee Age Profile</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Number, Percentage (%)</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">Below 30 : (12) 12% <br />30 to 50 years : (77) 79% <br />Above 50 : (9) 9%</TableCell>
                                    <TableCell sx={{ border: "1.5px solid #C3C4BD" }} align="left">FN-AC-330a.1</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography mt={1} variant="body2">Note: *Hazardous waste such as waste oil is generated & being reused.</Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default SASB;