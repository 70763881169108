import React from 'react';
import Counter from '../ReusableComponents/counter';

//MUI
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//Images
import im1 from '../../images/SDG/1.jpg';
import im4 from '../../images/SDG/4.jpg';
import im5 from '../../images/SDG/5.jpg';
import im8 from '../../images/SDG/8.jpg';
import im10 from '../../images/SDG/10.jpg';
import im16 from '../../images/SDG/16.jpg';
import section5 from '../../images/Banners/section5.webp'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SchoolIcon from '@mui/icons-material/School';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const counterData = [
    {
        "num": 99,
        "label": "Employees Trained on human rights issues and policy(ies)",
        "icon": SchoolIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "Employees Paid more than Minimum wage",
        "icon": CurrencyRupeeIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
    {
        "num": 100,
        "label": "Assessment of plants and offices on human rights",
        "icon": CheckCircleOutlineIcon,
        "prefix": "",
        "isdecimal": false,
        "suffix": "%"
    },
]


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBox = styled(Box)`
  ${({ theme }) => `
  cursor: pointer;
  transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
})};
  &:hover {
    transform: scale(2);
  }
  `}
`;


const Sectionc5 = () => {
    const [expanded1, setExpanded1] = React.useState(false);
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };

    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const [expanded3, setExpanded3] = React.useState(false);
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const [expanded4, setExpanded4] = React.useState(false);
    const handleExpandClick4 = () => {
        setExpanded4(!expanded4);
    };

    const [expanded5, setExpanded5] = React.useState(false);
    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    };

    const [expanded6, setExpanded6] = React.useState(false);
    const handleExpandClick6 = () => {
        setExpanded6(!expanded6);
    };

    const [expanded7, setExpanded7] = React.useState(false);
    const handleExpandClick7 = () => {
        setExpanded7(!expanded7);
    };

    const [expanded8, setExpanded8] = React.useState(false);
    const handleExpandClick8 = () => {
        setExpanded8(!expanded8);
    };

    const [expanded9, setExpanded9] = React.useState(false);
    const handleExpandClick9 = () => {
        setExpanded9(!expanded9);
    };

    const [expanded10, setExpanded10] = React.useState(false);
    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    };

    const [expanded11, setExpanded11] = React.useState(false);
    const handleExpandClick11 = () => {
        setExpanded11(!expanded11);
    };

    const [expanded12, setExpanded12] = React.useState(false);
    const handleExpandClick12 = () => {
        setExpanded12(!expanded12);
    };

    const [expanded13, setExpanded13] = React.useState(false);
    const handleExpandClick13 = () => {
        setExpanded13(!expanded13);
    };

    const [expanded14, setExpanded14] = React.useState(false);
    const handleExpandClick14 = () => {
        setExpanded14(!expanded14);
    };

    const [expanded15, setExpanded15] = React.useState(false);
    const handleExpandClick15 = () => {
        setExpanded15(!expanded15);
    };

    const [expand, setExpand] = React.useState(false);
    const expandAll = () => {
        setExpand(true); setExpanded1(true); setExpanded2(true); setExpanded3(true); setExpanded4(true); setExpanded5(true); setExpanded6(true); setExpanded7(true); setExpanded8(true); setExpanded9(true); setExpanded10(true); setExpanded11(true); setExpanded12(true); setExpanded13(true); setExpanded14(true); setExpanded15(true);
    };
    const closeAll = () => {
        setExpand(false); setExpanded1(false); setExpanded2(false); setExpanded3(false); setExpanded4(false); setExpanded5(false); setExpanded6(false); setExpanded7(false); setExpanded8(false); setExpanded9(false); setExpanded10(false); setExpanded11(false); setExpanded12(false); setExpanded13(false); setExpanded14(false); setExpanded15(false);
    };


    return (
        <Container>
            <Box mt={5} sx={{
                display: 'flex',
                alignItems: 'center',
                height: { xs: '150px', md: '300px' },
                backgroundColor: '#5a6054',
                borderRadius: '8px'
            }}>
                <Box px={5} py={3} sx={{ width: '100%' }}>
                    <Typography sx={{ typography: { xs: 'h5', md: 'h4' }, color: "#ffffff" }}>
                        Principle 5
                    </Typography>
                    <Typography mt={2} sx={{ typography: { xs: 'body1', md: 'h6' }, color: "#ffffff" }}>
                        Human Rights
                    </Typography>
                    <Box mt={2} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <Typography variant='body1' sx={{ color: "#ffffff" }}>
                            Businesses should respect and promote human rights
                        </Typography>
                    </Box>

                    <Box mt={3} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                        <StyledBox><img src={im1} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im4} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im5} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im8} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im10} height="40" alt="svg" /></StyledBox>
                        <StyledBox><img src={im16} height="40" alt="svg" /></StyledBox>
                    </Box>
                </Box>
                <Box sx={{
                    backgroundImage: `url(${section5})`,
                    backgroundSize: 'cover',
                    borderRadius: '0 8px 8px 0',
                    height: { xs: '150px', md: '300px' },
                    width: '100%'
                }}>
                </Box>
            </Box>
            <Box px={4} py={6} mt={8} sx={{ backgroundColor: '#f7f7ef', borderRadius: '8px' }}>
                <Box mb={6} display="flex" justifyContent="center">
                    <Typography variant='h4' sx={{ color: "#67725d", fontSize: "34px", fontWeight: "900" }}>
                        Highlights
                    </Typography>
                </Box>
                <Box>
                    <Counter data={counterData} />
                </Box>
            </Box>
            <Box mt={5} sx={{ textAlign: "right" }}>
                <Button variant="outlined" onClick={expand ? closeAll : expandAll}>
                    {!expand ? 'Expand All' : "Close All"}
                </Button>
            </Box>
            <Box mt={4}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Essential Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Employees and workers who have been provided training on human rights issues and policy(ies) of the entity, in the following format:
                            <ExpandMore
                                expand={expanded1}
                                onClick={handleExpandClick1}
                                aria-expanded={expanded1}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Category</strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2022-23<br />(Current Financial Year)</strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2021-22<br />(Previous Financial Year)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Total (A)</strong></TableCell>
                                        <TableCell align="center"><strong>No. of employees / workers covered (B)</strong></TableCell>
                                        <TableCell align="center"><strong>%(B / A)</strong></TableCell>
                                        <TableCell align="center"><strong>Total (C)</strong></TableCell>
                                        <TableCell align="center"><strong>No. of employees / workers covered (D) </strong></TableCell>
                                        <TableCell align="center"><strong>%(D / C)
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center' colSpan={7} sx={{ backgroundColor: "#e0e0e0" }} >
                                            <strong>Employees and workers</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            <strong>Permanent</strong>
                                        </TableCell>
                                        <TableCell align='center'>98 </TableCell>
                                        <TableCell align='center' >96
                                        </TableCell>
                                        <TableCell align='center'>99%  </TableCell>
                                        <TableCell align='center'>91
                                        </TableCell>
                                        <TableCell align='center'>91
                                        </TableCell>
                                        <TableCell align='center'>100%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            <strong>Other than permanent</strong>
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center' >-
                                        </TableCell>
                                        <TableCell align='center'>-  </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            <strong>Total Employees</strong>
                                        </TableCell>
                                        <TableCell align='center'>98 </TableCell>
                                        <TableCell align='center' >96
                                        </TableCell>
                                        <TableCell align='center'>99%  </TableCell>
                                        <TableCell align='center'>97
                                        </TableCell>
                                        <TableCell align='center'>97
                                        </TableCell>
                                        <TableCell align='center'>100%
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        2. Details of minimum wages paid to employees, in the following format:
                        <ExpandMore
                            expand={expanded2}
                            onClick={handleExpandClick2}
                            aria-expanded={expanded2}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong>Category</strong></TableCell>
                                        <TableCell align="center" colSpan={5}><strong>FY 2022-23<br />(Current Financial Year)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Total Count in Current FY</strong></TableCell>
                                        <TableCell align="center"><strong>Number of Employees Paid Minimum wage</strong></TableCell>
                                        <TableCell align="center"><strong>%age of Employees Paid Minimum wage</strong></TableCell>
                                        <TableCell align="center"><strong>Number of Employees Paid more than Minimum wage</strong></TableCell>
                                        <TableCell align="center"><strong>% age of Employees Paid more than Minimum wage</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center' colSpan={11} sx={{ backgroundColor: "#e0e0e0" }} >
                                            <strong>Employees</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" colSpan={11}>
                                            <strong>Permanent</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Male
                                        </TableCell>
                                        <TableCell align='center'>91</TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>91 </TableCell>
                                        <TableCell align='center'>
                                            100%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Female
                                        </TableCell>
                                        <TableCell align='center'>7 </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>7 </TableCell>
                                        <TableCell align='center'>
                                            100%
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" colSpan={11}>
                                            <strong>Other than Permanent</strong>
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Male
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Female
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center' colSpan={11} sx={{ backgroundColor: "#e0e0e0" }} >
                                            <strong>Workers</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" colSpan={11}>
                                            <strong>Permanent</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Male
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Female
                                        </TableCell>
                                        <TableCell align='center'></TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" colSpan={11}>
                                            <strong>Other than Permanent</strong>
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Male
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-

                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" >
                                            Female
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>
                                            -
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                        3. Details of remuneration/salary/wages, in the following format:
                        <ExpandMore
                            expand={expanded3}
                            onClick={handleExpandClick3}
                            aria-expanded={expanded3}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Typography>
                </Box>
                <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <Box mb={2}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Male</strong></TableCell>
                                        <TableCell align="center" colSpan={2}><strong>Female</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Number</strong></TableCell>
                                        <TableCell align="center"><strong>Median remuneration/ salary/ wages of
                                            respective category</strong></TableCell>
                                        <TableCell align="center"><strong>Number</strong></TableCell>
                                        <TableCell align="center"><strong>Median remuneration/ salary/ wages of
                                            respective category
                                        </strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Board of Directors (BODs)
                                        </TableCell>
                                        <TableCell align='center'>5 </TableCell>
                                        <TableCell align='center'>27,00,000 (Average)
                                        </TableCell>
                                        <TableCell align='center'>1 </TableCell>
                                        <TableCell align='center'>45,00,000 (Average)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Key Managerial Personnel
                                        </TableCell>
                                        <TableCell align='center'>1 </TableCell>
                                        <TableCell align='center'>3,70,00,000 (Average)
                                        </TableCell>
                                        <TableCell align='center'>1 </TableCell>
                                        <TableCell align='center'>41,60,000 (Average)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Employees other than BoD and KMP
                                        </TableCell>
                                        <TableCell align='center'>92 </TableCell>
                                        <TableCell align='center'>14,24,828 (Average)
                                        </TableCell>
                                        <TableCell align='center'>6 </TableCell>
                                        <TableCell align='center'>49,57,049 (Average)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Workers
                                        </TableCell>
                                        <TableCell align='center'>- </TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                        <TableCell align='center'>-</TableCell>
                                        <TableCell align='center'>-
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues caused or contributed to by the business? (Yes/No)
                            <ExpandMore
                                expand={expanded4}
                                onClick={handleExpandClick4}
                                aria-expanded={expanded4}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Yes. ESG Committee governed by BoD.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Describe the internal mechanisms in place to redress grievances related to human rights issues
                            <ExpandMore
                                expand={expanded5}
                                onClick={handleExpandClick5}
                                aria-expanded={expanded5}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded5} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            There are policies in place of Grievance redressal. Grievance redressal forms part of HR policy. In case of O&M workers, Complaint register is maintained at plant, however,
                            no complaints are registered yet. Grievance redressal mechanism procedure is available however there were no grievances received till now.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            6. Number of Complaints on the following made by employees and workers:
                            <ExpandMore
                                expand={expanded6}
                                onClick={handleExpandClick6}
                                aria-expanded={expanded6}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded6} timeout="auto" unmountOnExit>
                    <Box mb={2}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                                <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center" rowSpan={2}><strong></strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2022-23<br />(Current Financial Year)</strong></TableCell>
                                        <TableCell align="center" colSpan={3}><strong>FY 2021-22<br />(Previous Financial Year)</strong></TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: "#67725d" }}>
                                        <TableCell align="center"><strong>Filed during
                                            the year</strong></TableCell>
                                        <TableCell align="center"><strong>Pending
                                            resolution at
                                            the end of
                                            year</strong></TableCell>
                                        <TableCell align="center"><strong>Remarks</strong></TableCell>
                                        <TableCell align="center"><strong>Filed during
                                            the year</strong></TableCell>
                                        <TableCell align="center"><strong>Pending
                                            resolution at
                                            the end of
                                            year</strong></TableCell>
                                        <TableCell align="center"><strong>Remarks</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Sexual Harassment
                                        </TableCell>
                                        <TableCell align='center' colSpan={3} rowSpan={6}>No complaints registered till date </TableCell>
                                        <TableCell align='center' colSpan={3} rowSpan={6}>No complaints registered.  </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Discrimination at workplace
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Child Labour
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Forced Labour/Involuntary Labour
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Wages
                                        </TableCell>

                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" align='center'>
                                            Other human rights related issues
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            7. Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases.
                            <ExpandMore
                                expand={expanded7}
                                onClick={handleExpandClick7}
                                aria-expanded={expanded7}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded7} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            As per policy, complainant would be provided confidentiality at all times. No such cases registered so far.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            8. Do human rights requirements form part of your business agreements and contracts? (Yes/No)
                            <ExpandMore
                                expand={expanded8}
                                onClick={handleExpandClick8}
                                aria-expanded={expanded8}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded8} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Human rights forms part of HR policy. Also all the O&M contract & Code of conduct includes Human Rights requirements.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            9. Assessments for the year:
                            <ExpandMore
                                expand={expanded9}
                                onClick={handleExpandClick9}
                                aria-expanded={expanded9}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded9} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>Category</strong></TableCell>
                                    <TableCell align="center"><strong>% of your plants and offices that were assessed (by entity or statutory authorities or third parties)
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Child labor
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Forced/involuntary labor
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Sexual Harassment
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Discrimination at workplace
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Wages
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Others - please specify
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                        <Typography variant='body1'>
                            Note - Human rights forms part of HR policy. Also, all the O&M contract includes Human Rights requirements. However, no such assessments has taken place by third party or
                            Statutory authority.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            9. Provide details of any corrective actions taken or underway to address significant risks/concerns arising from the assessments at Question 9 above
                            <ExpandMore
                                expand={expanded10}
                                onClick={handleExpandClick10}
                                aria-expanded={expanded10}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded10} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Not Applicable
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={6}>
                <Typography variant='h4' sx={{ color: "#67725d", fontWeight: "bold" }}>
                    Leadership Indicators
                </Typography>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            1. Details of a business process being modified / introduced as a result of addressing human rights grievances/complaints.
                            <ExpandMore
                                expand={expanded11}
                                onClick={handleExpandClick11}
                                aria-expanded={expanded11}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded11} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Our business agreement and documents provide for compliance with applicable law. No complaints registered till date.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            2. Details of the scope and coverage of any Human rights due diligence conducted.
                            <ExpandMore
                                expand={expanded12}
                                onClick={handleExpandClick12}
                                aria-expanded={expanded12}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded12} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            We conduct standard legal due diligence which also includes compliance with applicable law and labor law.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            3. Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the Rights of Persons with Disabilities Act, 2016?
                            <ExpandMore
                                expand={expanded13}
                                onClick={handleExpandClick13}
                                aria-expanded={expanded13}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded13} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            Our corporate offices have lift and ramps to facilitate access.
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            4. Details on assessment of value chain partners:
                            <ExpandMore
                                expand={expanded14}
                                onClick={handleExpandClick14}
                                aria-expanded={expanded14}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded14} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="energy consumption">
                            <TableHead sx={{ "& th": { color: '#FFFFFF' } }}>
                                <TableRow sx={{ backgroundColor: "#67725d" }}>
                                    <TableCell align="center"><strong>Category</strong></TableCell>
                                    <TableCell align="center"><strong>% of value chain partners (by value of business done with such partners) that were
                                        assessed
                                    </strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ "& tr": { borderBottom: "1.5px solid #C3C4BD" } }}>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Child labor
                                    </TableCell>
                                    <TableCell align='center'>100% </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Forced/involuntary labor
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Sexual Harassment
                                    </TableCell>
                                    <TableCell align='center'>100%  </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Discrimination at workplace
                                    </TableCell>
                                    <TableCell align='center'>100%  </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Wages
                                    </TableCell>
                                    <TableCell align='center'>100%  </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center'>
                                        Others - please specify
                                    </TableCell>
                                    <TableCell align='center'>- </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={5} mt={4}>
                        <Typography variant='body1'>
                            Note - O&M Contractors are VRET's largest value chain partner in terms of business done
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box mt={2} mb={5} sx={{ borderBottom: 1, borderColor: "#d3d3c8" }}>
                <Box mb={2}>
                    <Box>
                        <Typography variant='body1' sx={{ fontWeight: 'bold', color: "#67725d" }}>
                            5. Provide details of any corrective actions taken or underway to address significant risks/concerns arising from the assessments at Question 4 above
                            <ExpandMore
                                expand={expanded15}
                                onClick={handleExpandClick15}
                                aria-expanded={expanded15}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </Typography>
                    </Box>
                </Box>
                <Collapse in={expanded15} timeout="auto" unmountOnExit>
                    <Box mb={5}>
                        <Typography variant='body1'>
                            -
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
}


export default Sectionc5;